import * as React from 'react'

function SvgKobi(props) {
  return (
    <svg width={512} height={512} viewBox="0 0 64 64" className="" {...props}>
      <path d="M63 58h-3V21.195A6.003 6.003 0 0063 16v-2a1 1 0 00-.147-.522l-7.963-13A1 1 0 0054.037 0H9.963a.998.998 0 00-.852.478l-7.963 13A.99.99 0 001 14v2a6.005 6.005 0 003 5.195V27h2v-5.084a6 6 0 006-2.603A6 6 0 0017 22a6 6 0 005-2.687A6 6 0 0027 22a6 6 0 005-2.687A6 6 0 0037 22a6 6 0 005-2.687A6 6 0 0047 22a6 6 0 005-2.687 6 6 0 006 2.603V58h-4V34h-2v10H36V28h16v4h2v-5a1 1 0 00-1-1H27v2h7v30H22v2h40v2H2v-2h8v-2H1a1 1 0 00-1 1v4a1 1 0 001 1h62a1 1 0 001-1v-4a1 1 0 00-1-1zm-2.173-44h-8.069L49.39 2h4.087zM47.312 2l3.368 12h-7.807L41.244 2zM33 2h6.226l1.629 12H33zm-2 12h-7.855l1.629-12H31zm-9.874 0h-7.807l3.368-12h6.068zM10.523 2h4.087l-3.368 12H3.173zM7 20c-2.206 0-4-1.794-4-4h8c0 2.206-1.794 4-4 4zm10 0c-2.206 0-4-1.794-4-4h8c0 2.206-1.794 4-4 4zm10 0c-2.206 0-4-1.794-4-4h8c0 2.206-1.794 4-4 4zm10 0c-2.206 0-4-1.794-4-4h8c0 2.206-1.794 4-4 4zm10 0c-2.206 0-4-1.794-4-4h8c0 2.206-1.794 4-4 4zm6-4h8c0 2.206-1.794 4-4 4s-4-1.794-4-4zM36 58v-8h2v-2h-2v-2h16v2H40v2h12v8z" />
      <path d="M1.356 40.781a24.93 24.93 0 009.496 15.564l4.543 3.452a1.004 1.004 0 001.205.003l4.413-3.31a24.911 24.911 0 009.62-15.655l1.352-7.661a1 1 0 00-.514-1.056l-15-8a.999.999 0 00-.941 0l-15 8a1 1 0 00-.514 1.056zM16 26.133l13.889 7.408-1.226 6.946a22.92 22.92 0 01-8.85 14.403l-3.809 2.857-3.942-2.995a22.935 22.935 0 01-8.736-14.319l-1.215-6.892z" />
      <path d="M4.998 41.057a18.552 18.552 0 007.065 11.58l3.332 2.531a.998.998 0 001.205.004l3.236-2.427a18.537 18.537 0 007.157-11.647l.992-5.618a1 1 0 00-.514-1.056l-5.002-2.668-.941 1.765 4.362 2.327-.866 4.903a16.545 16.545 0 01-6.387 10.396l-2.632 1.974-2.73-2.075A16.551 16.551 0 016.97 40.712l-.86-4.866 4.361-2.326-.941-1.765-5.001 2.667a1 1 0 00-.514 1.056z" />
      <path d="M15.999 30.572l3.531 1.883.941-1.765-4.002-2.134a.999.999 0 00-.941 0l-3.999 2.133.941 1.765zM14.962 46H15a1 1 0 00.752-.341l7-8-1.505-1.317-6.188 7.072L12.8 40.4l-1.6 1.2 3 4c.181.241.461.388.762.4zM15 33h2v2h-2zM15 48h2v2h-2z" />
    </svg>
  )
}

export default SvgKobi
