import * as React from 'react'

function SvgOzel(props) {
  return (
    <svg width={512} height={512} viewBox="0 0 64 64" className="" {...props}>
      <path d="M38 13h-3v-3a1 1 0 00-1-1h-4a1 1 0 00-1 1v3h-3a1 1 0 00-1 1v4a1 1 0 001 1h3v3a1 1 0 001 1h4a1 1 0 001-1v-3h3a1 1 0 001-1v-4a1 1 0 00-1-1zm-1 4h-3a1 1 0 00-1 1v3h-2v-3a1 1 0 00-1-1h-3v-2h3a1 1 0 001-1v-3h2v3a1 1 0 001 1h3zM59.73 18.36a4.578 4.578 0 00.03-.53 4.02 4.02 0 00-3.18-3.91 4.071 4.071 0 00-4.7 2.97l-4.4 12.66a1.632 1.632 0 00-.19.09l-8.84 5.18A9.017 9.017 0 0034 42.58V52a1 1 0 00-1 1v8a1 1 0 001 1h14a1 1 0 001-1v-8a1 1 0 00-1-1v-2.2a3.039 3.039 0 011-2.24l6.76-6.01a4.931 4.931 0 001.52-2.45l4.34-16.23a3.955 3.955 0 00.14-1.04 4.019 4.019 0 00-2.03-3.47zm-5.94-.88a2.113 2.113 0 012.38-1.6 2 2 0 011.59 1.95v.02a4.1 4.1 0 00-3.89 3.06l-2.79 8.61a4.2 4.2 0 00-1.34-.38zM47 60H35v-6h12zm12.69-37.65l-4.35 16.24a2.981 2.981 0 01-.9 1.46l-6.77 6.01A5.056 5.056 0 0046 49.8V52H36v-9.42a7.052 7.052 0 013.46-6.04l8.83-5.17a2 2 0 012.74.73 2.06 2.06 0 01.2 1.52 2 2 0 01-.94 1.21l-5.73 3.31a1 1 0 001 1.73l5.73-3.31a3.948 3.948 0 001.87-2.43 4.007 4.007 0 00-.4-3.03l-.06-.09 3.09-9.53a2.113 2.113 0 012.38-1.6 2 2 0 011.59 1.95 1.722 1.722 0 01-.07.52zM30 52v-9.42a9.017 9.017 0 00-4.45-7.76l-8.84-5.19c-.06-.03-.13-.05-.19-.08l-4.37-12.59a4.107 4.107 0 00-4.73-3.04 4.02 4.02 0 00-3.18 3.91 4.578 4.578 0 00.03.53 4.019 4.019 0 00-2.03 3.47 4.421 4.421 0 00.13 1.04l4.35 16.22a4.916 4.916 0 001.52 2.46l6.75 6.01A3.012 3.012 0 0116 49.8V52a1 1 0 00-1 1v8a1 1 0 001 1h14a1 1 0 001-1v-8a1 1 0 00-1-1zM6.24 17.83a2 2 0 011.58-1.95 2.161 2.161 0 012.42 1.67l4.02 11.59a4.2 4.2 0 00-1.34.38l-2.77-8.56a4.149 4.149 0 00-3.91-3.11zm10.08 28.23l-6.76-6.01a2.965 2.965 0 01-.9-1.47L4.3 22.35a2.121 2.121 0 01-.06-.52 2 2 0 011.58-1.95 2.144 2.144 0 012.41 1.65l3.07 9.48-.06.09a4.007 4.007 0 00-.4 3.03 3.948 3.948 0 001.87 2.43l5.73 3.31a1 1 0 001-1.73l-5.73-3.31a2 2 0 01-.94-1.21 2.06 2.06 0 01.2-1.52 2 2 0 012.73-.74l8.84 5.18A7.052 7.052 0 0128 42.58V52H18v-2.2a5.03 5.03 0 00-1.68-3.74zM29 60H17v-6h12z" />
      <path d="M46.147 5.132A9.994 9.994 0 0032 5.133a9.992 9.992 0 00-14.146 0 10.005 10.005 0 000 14.134l13.44 13.44a1 1 0 001.414 0l13.44-13.44a10.006 10.006 0 00-.001-14.135zM32 30.586L19.267 17.853A8 8 0 0124.93 4.2a7.916 7.916 0 015.653 2.347l.71.71a1 1 0 001.414 0l.712-.712a7.993 7.993 0 0111.314 0 8 8 0 010 11.306z" />
    </svg>
  )
}

export default SvgOzel
