import * as React from 'react'

function SvgLinkedin(props) {
  return (
    <svg viewBox="0 0 430.1 430.1" className="" {...props}>
      <path d="M398.4 0H31.8C14.2 0 0 13.8 0 30.8v368.5c0 17 14.2 30.8 31.8 30.8h366.6c17.5 0 31.8-13.8 31.8-30.8V30.8c-.1-17-14.3-30.8-31.8-30.8zm-268 360h-65V165.8h65V360zM97.9 139.3h-.4c-21.8 0-35.9-14.9-35.9-33.6 0-19 14.5-33.5 36.8-33.5 22.2 0 35.9 14.5 36.3 33.5 0 18.7-14.1 33.6-36.8 33.6zM364.7 360h-65V256.1c0-26.1-9.4-43.9-32.9-43.9-18 0-28.6 12-33.3 23.6-1.7 4.1-2.2 9.9-2.2 15.7V360h-65s.9-176 0-194.2h65v27.5c8.6-13.2 24-32.1 58.5-32.1 42.8 0 74.8 27.7 74.8 87.4l.1 111.4zM230.9 194c.1-.2.3-.4.4-.6v.6h-.4z" />
    </svg>
  )
}

export default SvgLinkedin
