import * as React from 'react'

function SvgMask(props) {
  return (
    <svg className="" viewBox="0 0 1921 819.8" {...props}>
      <path
        fill="#fff"
        d="M1921 413.1v406.7H0V.5h.4l228.1 598.3c30 74.4 80.8 130.6 152.5 168.6 107.6 57 212.1 40.7 245.7 34.4 22.4-4.2 54.9-13.1 97.5-26.6L1921 400.5v12.6z"
      />
      <path
        fill="#fff"
        d="M1921 387.9v431.9H0V0h13l226.6 594.4c14.4 35.7 34 67.4 58.3 94.3 24.4 26.9 54.2 49.8 88.6 68.1 50.2 26.6 105.8 40.1 165.2 40.1 36.1 0 63.6-5.2 72.6-6.9 21.6-4 53.9-12.9 96.1-26.2L1921 387.9z"
        opacity={0.05}
      />
    </svg>
  )
}

export default SvgMask
