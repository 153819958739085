import * as React from 'react'

function SvgCepkask(props) {
  return (
    <svg width={512} height={512} viewBox="0 0 64 64" className="" {...props}>
      <path d="M57.478 21.18l-13-7.059a1.005 1.005 0 00-.955 0l-13 7.059a1 1 0 00-.508 1.05l1.649 9.521a14.963 14.963 0 005.602 9.305l6.122 4.736a1.006 1.006 0 001.218.003l6.039-4.611a14.959 14.959 0 005.677-9.36l1.663-9.594a1 1 0 00-.507-1.05zm-3.126 10.302a12.967 12.967 0 01-4.919 8.112l-5.429 4.145-5.514-4.266a12.964 12.964 0 01-4.855-8.064l-1.527-8.814L44 16.138l11.893 6.457z" />
      <path d="M50.862 30.876a8.661 8.661 0 01-3.29 5.424l-3.569 2.727-3.631-2.809a8.67 8.67 0 01-3.246-5.392l-1.02-5.884 2.371-1.287-.955-1.758-3.001 1.629a1 1 0 00-.508 1.05l1.142 6.591a10.666 10.666 0 003.993 6.633l4.238 3.279a1.003 1.003 0 001.219.004l4.18-3.193a10.662 10.662 0 004.047-6.672l1.151-6.642a1 1 0 00-.508-1.05l-2.997-1.627-.955 1.758 2.367 1.285z" />
      <path d="M43.521 18.64l-3.999 2.171.955 1.758 3.521-1.913 3.526 1.916.955-1.758-4.004-2.174a1.003 1.003 0 00-.954 0zM40.293 29.707l3 3A.997.997 0 0044 33l.045-.001c.281-.013.543-.143.724-.359l5-6-1.537-1.28-4.299 5.159-2.226-2.226zM43 35h2v2h-2zM43 23h2v2h-2z" />
      <path d="M38 61c0 .551-.448 1-1 1H9c-.552 0-1-.449-1-1V3c0-.551.448-1 1-1h28c.552 0 1 .449 1 1v11h2V3c0-1.654-1.346-3-3-3H9C7.346 0 6 1.346 6 3v58c0 1.654 1.346 3 3 3h28c1.654 0 3-1.346 3-3V45h-2z" />
      <path d="M36 47v-5h-2v5c0 .551-.448 1-1 1H13c-.552 0-1-.449-1-1V20h-2v27c0 1.654 1.346 3 3 3h20c1.654 0 3-1.346 3-3zM36 16v-5c0-1.654-1.346-3-3-3H13c-1.654 0-3 1.346-3 3v6.833h2V11c0-.551.448-1 1-1h20c.552 0 1 .449 1 1v5zM18 4h10v2H18zM19 56c0 2.206 1.794 4 4 4s4-1.794 4-4-1.794-4-4-4-4 1.794-4 4zm6 0c0 1.103-.897 2-2 2s-2-.897-2-2 .897-2 2-2 2 .897 2 2z" />
      <path d="M14 14h2v2h-2zM18 12h2v4h-2zM28 12h4v2h-4zM26 32h2v2h-2zM26 36h2v2h-2zM24 34h2v2h-2zM28 34h2v2h-2zM54 10h2v2h-2zM54 14h2v2h-2zM52 12h2v2h-2zM56 12h2v2h-2z" />
    </svg>
  )
}

export default SvgCepkask
