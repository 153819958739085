import * as React from 'react'

function SvgTrafik(props) {
  return (
    <svg width={512} height={512} viewBox="0 0 512 512" className="" {...props}>
      <path d="M512 180.116H399.244l-61.873 72.146L256 279.392l-78.927-26.314-31.329-72.961H0v220.159h103.22c6.387 20.277 25.364 35.024 47.724 35.024 22.359 0 41.336-14.747 47.723-35.024h114.665c6.387 20.277 25.364 35.024 47.723 35.024 22.36 0 41.337-14.747 47.724-35.024H512zm-98.963 30H482v40.063H378.679zM143.18 250.179H90.004v-40.063h35.973zm-83.176-40.063v40.063H30v-40.063zm90.94 195.184c-11.042 0-20.024-8.983-20.024-20.024s8.982-20.024 20.024-20.024c11.041 0 20.023 8.983 20.023 20.024s-8.982 20.024-20.023 20.024zM241 370.275h-42.333c-6.387-20.277-25.364-35.024-47.723-35.024-22.36 0-41.337 14.747-47.724 35.024H30v-90.096h133.511L241 306.014zM361.056 405.3c-11.041 0-20.023-8.983-20.023-20.024s8.982-20.024 20.023-20.024c11.042 0 20.024 8.983 20.024 20.024s-8.982 20.024-20.024 20.024zm47.724-35.025c-6.387-20.277-25.364-35.024-47.724-35.024-22.359 0-41.336 14.747-47.723 35.024H271v-64.261l77.489-25.835H482v90.096zM241 76.7h30v98.415h-30z" />
      <path d="M138.397 110.79l25.98-15 49.207 85.227-25.98 15zM298.407 181.03l49.207-85.227 25.98 15-49.207 85.226z" />
    </svg>
  )
}

export default SvgTrafik
