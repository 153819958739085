import * as React from 'react'

function SvgMesleki(props) {
  return (
    <svg width={512} height={512} viewBox="0 0 512 512" className="" {...props}>
      <g data-name="Out line">
        <path d="M232 424h-8v-24a8 8 0 00-8-8H40a8 8 0 00-8 8v24h-8a8 8 0 00-8 8v48a8 8 0 008 8h208a8 8 0 008-8v-48a8 8 0 00-8-8zM48 408h160v16H48zm176 64H32v-32h192zM20.853 359.312l5.936-14.848 39.98 15.983-5.937 14.848zM35.2 318.4l9.6-12.8 32 24-9.6 12.8zM57.6 284.8l12.8-9.6 24 32-12.8 9.6zM341.088 127.598l-2.745 2.745 11.314 11.314 2.745-2.745a146.17 146.17 0 0173.255-39.736l-3.314-15.653a162.127 162.127 0 00-81.255 44.075zM456 96V80a163.257 163.257 0 00-16.828.873l1.656 15.914A147.082 147.082 0 01456 96zM336.57 88.978l-21.482 16.708 9.824 12.628 21.481-16.708a179.163 179.163 0 0178.98-34.96l-2.746-15.763a195.228 195.228 0 00-86.057 38.095zM456 64V48h-.001c-5.55 0-11.164.241-16.686.717l1.373 15.941C445.754 64.222 450.906 64 456 64zM450.51 32H456V16h-5.49c-3.612 0-7.277.088-10.894.262l.768 15.982A211.17 211.17 0 01450.51 32zM424.97 33.555l-1.94-15.883a227.542 227.542 0 00-132.687 64.67l11.314 11.315A211.458 211.458 0 01424.97 33.555zM477.657 426.343l-96-96a36.176 36.176 0 00-26.588-10.588L315.314 280l2.343-2.343a8 8 0 000-11.314L283.314 232 304 211.314l2.343 2.342a30.627 30.627 0 1043.314-43.313l-80-80a30.627 30.627 0 00-43.314 43.313v.001l2.343 2.343L136 228.686l-2.343-2.342a30.627 30.627 0 00-43.314 43.312v.001l80 80a30.627 30.627 0 1043.314-43.314L211.314 304 232 283.314l34.343 34.343a8 8 0 0011.314 0l2.343-2.343 39.755 39.755a36.176 36.176 0 0010.588 26.588l96 96a8 8 0 0011.314 0l40-40a8 8 0 000-11.314zm-240-324.687a14.626 14.626 0 0120.686.001l80 80a14.627 14.627 0 11-20.686 20.686l-80-80a14.646 14.646 0 010-20.687zm-35.314 236.688a14.626 14.626 0 01-20.686-.001l-80-80a14.627 14.627 0 1120.686-20.686l80 80a14.646 14.646 0 010 20.687zM147.313 240L240 147.314 292.686 200 200 292.686zm96 32L272 243.314 300.687 272 272 300.687zm48.001 32L304 291.314l33.481 33.481a36.35 36.35 0 00-12.686 12.686zM432 460.687l-90.343-90.344a20.284 20.284 0 1128.686-28.686L460.686 432z" />
      </g>
    </svg>
  )
}

export default SvgMesleki
