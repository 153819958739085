import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styles from './section.module.css'
import SimpleSlider from './slider'
function Section() {
  return (
    <StaticQuery
      query={graphql`
        {
          sectionData: allMarkdownRemark(
            filter: { fileAbsolutePath: { regex: "/home/sections/" } }
            sort: {fields: frontmatter___sort}
          ) {
            edges {
              node {
                id
                frontmatter {
                  url
                  headTitle
                  hero
                  headTitle
                  buttonText
                  content
                  title
                  featuredImage {
                    childImageSharp {
                      fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        return (
          <section role="main" aria-label="Neden Bizi Seçmelisiniz" className={styles.section}>
            <div className="sectioncss container position-relative">
              <SimpleSlider data={data.sectionData.edges} />
            </div>
          </section>
        )
      }}
    />
  )
}
export default Section
