import * as React from 'react'

function SvgOffer(props) {
  return (
    <svg width={512} height={512} viewBox="0 0 512 512" className="" {...props}>
      <path d="M46.443 0v512h419.113V0zm389.114 482H76.443V30h89.89v70.333h179.334V30h89.89zM315.667 30v40.333H196.333V30z" />
      <path d="M194.832 155.667h183.835v30H194.832zM133.333 155.667h31.499v30h-31.499zM194.832 219.667h183.835v30H194.832zM133.333 219.667h31.499v30h-31.499zM133.333 283.667h31.499v30h-31.499zM194.832 283.667h183.835v30H194.832zM133.333 379.667h80v30h-80zM298.667 394.787l-21.394-21.393-21.213 21.212 42.607 42.607 63.939-63.94-21.212-21.213z" />
    </svg>
  )
}

export default SvgOffer
