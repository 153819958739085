import { graphql, Link, StaticQuery } from 'gatsby'
import style from './header.module.css'
import Img from 'gatsby-image'
import React from 'react'
import styles from './header.module.css'
function Logo({ white }) {
  return (
    <StaticQuery
      query={graphql`
        {
          logowhite: file(name: { eq: "logo-white" }) {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
          logo: file(name: { eq: "logo" }) {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={(data) => (
        <Link
          className={[style.logo, 'nohover', white && style.white].join(' ')}
          to="/">
          <Img
            className={style.imgWhite}
            fluid={data.logowhite.childImageSharp.fluid}
          />
          <Img className={style.img} fluid={data.logo.childImageSharp.fluid} />
          <div className="d-flex flex-column align-items-end justify-content-center ml-2">
            <h2 className={styles.h2}>TiMURLAR SiGORTA</h2>
            <h3 className={styles.h3}>ÇATALCA</h3>
          </div>
        </Link>
      )}
    />
  )
}
export default Logo
