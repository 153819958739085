import * as React from 'react'

function SvgBlog(props) {
  return (
    <svg width={512} height={512} viewBox="0 0 59 57" className="" {...props}>
      <g fill="#000" fillRule="nonzero">
        <path d="M58.08.52c-.19 4.02-1.84 12.35-11.62 18.64a.423.423 0 00-.07.05 6.4 6.4 0 01-2.84-1.04 1 1 0 00-1.1 1.66 8.546 8.546 0 001.62.83c-2.78 1.72-4.76 2.82-6.29 3.66a18.9 18.9 0 00-5.06 3.5 10.089 10.089 0 01-5.27-.71 1 1 0 00-.9 1.78c1.39.659 2.911.997 4.45.99-.88 1.17-1.93 2.72-3.32 4.8-2.5 1.1-6.04 2.02-9.23.42a1.006 1.006 0 10-.9 1.8 10.372 10.372 0 004.67 1.06 13.766 13.766 0 003.31-.43 15.225 15.225 0 01-14.36 4.97 6.143 6.143 0 01-2.67-1.28c.18-.77.38-1.56.61-2.37 5.69-15.13 12.64-17.2 22.26-20.06a73.693 73.693 0 0017.13-6.92 1.003 1.003 0 00-1-1.74 71.471 71.471 0 01-16.7 6.74c-9.79 2.91-17.51 5.21-23.56 21.27l-.43.87a.355.355 0 00-.04.09 5.7 5.7 0 01-.35-.8 22.367 22.367 0 01-.46-14.01c.02-.09.06-.18.09-.27a9.35 9.35 0 001.12 2.53A1 1 0 008 27a.939.939 0 00.55-.17.99.99 0 00.28-1.38 12.575 12.575 0 01-1.45-4.75 23.756 23.756 0 017.03-8.48A17.387 17.387 0 0013 19a1 1 0 002 0 16.151 16.151 0 012.86-8.97 26.72 26.72 0 018.67-2.84 18.229 18.229 0 00-1.42 2.36 1 1 0 00.44 1.34.908.908 0 00.45.11.981.981 0 00.89-.55 16.334 16.334 0 012.41-3.53c2.31-.14 4.64-.1 6.91-.04.81.01 1.61.03 2.41.04-.24.599-.437 1.214-.59 1.84a1 1 0 001.94.48c.197-.804.486-1.582.86-2.32 5.97-.09 11.93-1.07 17.25-6.4z" />
        <path d="M11 55H3.42l3.29-3.29a1 1 0 00.29-.67 55.751 55.751 0 011.5-9.82 6.663 6.663 0 01-1.73-2.12 30.168 30.168 0 01-2.6 4.35A24.662 24.662 0 000 56a.987.987 0 00.62.92A.838.838 0 001 57h10a1 1 0 000-2zM5.73 44.7a55.863 55.863 0 00-.71 5.86l-2.71 2.71a23.8 23.8 0 013.42-8.57zM47 55H15a1 1 0 000 2h32a1 1 0 000-2z" />
      </g>
    </svg>
  )
}

export default SvgBlog
