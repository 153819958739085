import * as React from 'react'

function SvgNext(props) {
  return (
    <svg
      width={27}
      height={27}
      fill="none"
      viewBox="0 0 27 27"
      className=""
      {...props}>
      <circle cx={13.5} cy={13.5} r={13.5} fill="#C4C4C4" fillOpacity={0.05} />
      <circle cx={13.5} cy={13.5} r={13} stroke="#fff" strokeOpacity={0.5} />
    </svg>
  )
}

export default SvgNext
