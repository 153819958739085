import * as React from 'react'

function SvgMail(props) {
  return (
    <svg
      width={12}
      height={9}
      fill="none"
      viewBox="0 0 12 9"
      className=""
      {...props}>
      <path
        fill="#FCFCFC"
        d="M10.875 0h-9.75C.504 0 0 .504 0 1.125v6.75C0 8.496.504 9 1.125 9h9.75C11.496 9 12 8.496 12 7.875v-6.75C12 .504 11.496 0 10.875 0zm-9.75.75h9.75c.206 0 .375.169.375.375v.97c-.513.434-1.247 1.032-3.53 2.843C7.324 5.252 6.544 6.01 6 6c-.544.01-1.327-.748-1.72-1.062C1.997 3.127 1.263 2.528.75 2.095v-.97C.75.919.919.75 1.125.75zm9.75 7.5h-9.75a.376.376 0 01-.375-.375V3.07c.534.439 1.378 1.116 3.063 2.454.48.385 1.33 1.23 2.187 1.226.853.007 1.695-.832 2.187-1.226A277.865 277.865 0 0011.25 3.07v4.805a.376.376 0 01-.375.375z"
      />
    </svg>
  )
}

export default SvgMail
