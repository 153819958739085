import * as React from 'react'

function SvgPati(props) {
  return (
    <svg width={512} height={512} viewBox="0 0 60 60" className="" {...props}>
      <g fill="#000" fillRule="nonzero">
        <path d="M45.608 30.426l-9 3.828a1 1 0 00-.608.92v10.577a5.355 5.355 0 002.314 4.362l7.112 4.987a1 1 0 001.148 0l7.113-4.985A5.354 5.354 0 0056 45.751V35.174a1 1 0 00-.608-.92l-9-3.828a1 1 0 00-.784 0zM54 35.835v9.916a3.356 3.356 0 01-1.462 2.724L46 53.057l-6.537-4.582A3.354 3.354 0 0138 45.751v-9.916l8-3.4 8 3.4z" />
        <path d="M41.631 43.515a1 1 0 10-1.262 1.552l3.331 2.708a1 1 0 001.436-.183l6.667-9.029a1 1 0 10-1.61-1.188l-6.043 8.188-2.519-2.048z" />
        <path d="M5.817 52.1a4.88 4.88 0 003.074.9 28.92 28.92 0 006.847-1.344A45.988 45.988 0 0127.89 49.56c1.733.014 3.462.17 5.169.466a9.464 9.464 0 002.96 3.362L45 59.686a1.74 1.74 0 001.994 0l8.984-6.3A9.354 9.354 0 0060 45.751V32.529a1 1 0 00-.608-.921l-11.005-4.68a7.536 7.536 0 006.235-3.991c2-3.472 1.327-7.629-1.509-9.266a5.425 5.425 0 00-4.813-.218 8.515 8.515 0 00-5.053 8.747 5.474 5.474 0 002.334 3.887l-2.036.866C38.928 21.824 33.4 19 27.89 19c-13.695 0-24 16.737-24 27.59 0 2.751.61 4.501 1.927 5.51zM58 45.751a7.353 7.353 0 01-3.167 6L46 57.942l-8.833-6.192a7.353 7.353 0 01-3.167-6V33.19l12-5.1 12 5.1v12.561zM46.067 18a6.169 6.169 0 012.975-2.688 4.1 4.1 0 011.52-.31 3.064 3.064 0 011.551.4c1.881 1.086 2.23 4.017.777 6.534-1.453 2.517-4.164 3.683-6.047 2.595a3.472 3.472 0 01-1.616-2.614 6.169 6.169 0 01.84-3.917zM27.89 21c4.751 0 9.575 2.4 13.706 6.786l-8.988 3.822a1 1 0 00-.608.921v13.222c.002.716.088 1.43.256 2.125A33.15 33.15 0 0029 47.588V35a1 1 0 00-2 0v12.587a50.943 50.943 0 00-11.784 2.133c-3.654.989-6.8 1.843-8.184.789-.768-.589-1.142-1.87-1.142-3.919 0-10.106 9.855-25.59 22-25.59zM19.471 17.874c.351 0 .701-.034 1.046-.1 3.789-.734 6.1-5.3 5.161-10.166a10.458 10.458 0 00-3.11-5.771A6.309 6.309 0 0017.1.1c-3.79.732-6.106 5.293-5.163 10.166.853 4.425 4.105 7.608 7.534 7.608zM17.477 2.065A3.6 3.6 0 0118.155 2a4.621 4.621 0 013.07 1.319 8.446 8.446 0 012.49 4.669c.733 3.789-.872 7.3-3.578 7.822-2.706.522-5.506-2.133-6.237-5.922-.731-3.789.87-7.299 3.577-7.823zM7.482 13.453a5.431 5.431 0 00-4.814.217c-2.836 1.638-3.512 5.794-1.508 9.267a7.534 7.534 0 006.247 3.992 5.022 5.022 0 002.532-.665 5.429 5.429 0 002.6-4.06A8.175 8.175 0 0011.447 17a8.167 8.167 0 00-3.965-3.547zm3.074 8.465a3.473 3.473 0 01-1.617 2.614c-1.881 1.087-4.594-.078-6.046-2.595-1.452-2.517-1.106-5.449.775-6.534A3.067 3.067 0 015.219 15a4.1 4.1 0 011.521.31A6.165 6.165 0 019.715 18a6.176 6.176 0 01.841 3.918z" />
        <path d="M36.312 17.874c3.427 0 6.68-3.183 7.537-7.606C44.791 5.4 42.476.835 38.687.1a6.311 6.311 0 00-5.473 1.735A10.458 10.458 0 0030.1 7.608c-.943 4.871 1.373 9.432 5.163 10.166.346.067.697.1 1.049.1zm-4.245-9.886a8.448 8.448 0 012.491-4.669A4.617 4.617 0 0137.627 2c.228 0 .455.022.679.065 2.707.524 4.312 4.033 3.58 7.823-.732 3.79-3.541 6.445-6.24 5.922-2.699-.523-4.312-4.033-3.579-7.822zM22 45a1 1 0 001-1v-6a1 1 0 00-2 0v6a1 1 0 001 1z" />
      </g>
    </svg>
  )
}

export default SvgPati
