import React from 'react'
import Img from 'gatsby-image'
import { StaticQuery, graphql,Link } from 'gatsby'
import styles from './banner.module.css'
import {Mask} from "./icons";

function Banner() {
  return (
    <StaticQuery
      query={graphql`
        {
          homeImage: file(name: { eq: "banner" }) {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={(data) => (
        <div className={styles.banner}>
          <div className="container h-100">
            <div className="row h-100">
                <div className="col-md-10">
                    <div className={styles.bannerText}>
                        <span className="h6">Biliyor Musunuz?</span>
                        <h1>
                            Trafik Sigortasında <br />
                            <strong>Fark Yaratan Fiyatlarla hizmetinizdeyiz.</strong>
                        </h1>
                        <Link className="btn btn-banner" to="/teklif-al">
                            Hemen Teklif Al
                        </Link>
                    </div>
                </div>
            </div>
          </div>
          <div className="overlaybg">
            <Img fluid={data.homeImage.childImageSharp.fluid} />
          </div>
            <span className={styles.mask}>
                <Mask/>
            </span>
        </div>
      )}
    />
  )
}
export default Banner
