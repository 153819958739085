import React from 'react'

import styles from './html.module.css'

function Html({ children }) {
  return (
    <article
      className={styles.content}
      dangerouslySetInnerHTML={{
        __html: children
      }}></article>
  )
}

export default Html
