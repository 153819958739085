import * as React from 'react'

function SvgQuot(props) {
  return (
    <svg
      width={58}
      height={53}
      fill="none"
      viewBox="0 0 58 53"
      className=""
      {...props}>
      <path
        fill="#F4F4ED"
        d="M25.43 37.719a24.83 24.83 0 01-1.477 5.625c-.703 1.734-1.547 3.281-2.531 4.64-.985 1.313-2.063 2.368-3.235 3.164-1.125.75-2.273 1.125-3.445 1.125-1.078 0-2.203-.07-3.375-.21a26.045 26.045 0 01-3.305-.774 17.054 17.054 0 01-2.953-1.406 7.722 7.722 0 01-2.32-2.11 15.599 15.599 0 01-1.125-1.828c-.328-.75-.61-1.64-.844-2.672C.633 42.195.516 40.93.47 39.477c0-1.454.14-3.164.422-5.133a64.468 64.468 0 011.757-8.157c.797-2.671 2.016-5.367 3.657-8.085 1.687-2.72 3.914-5.532 6.68-8.438 2.812-2.953 6.398-6.117 10.757-9.492l4.781 6.75c-2.671 2.016-4.945 3.914-6.82 5.695-1.875 1.735-3.422 3.399-4.64 4.992-1.22 1.547-2.133 3-2.743 4.36-.61 1.312-.984 2.554-1.125 3.726-.187 1.125.117 1.992.914 2.602.797.562 1.852 1.031 3.164 1.406 2.672.703 4.758 1.781 6.258 3.235 1.5 1.406 2.133 3 1.899 4.78zm29.25 0a24.83 24.83 0 01-1.477 5.625c-.703 1.734-1.547 3.281-2.531 4.64-.984 1.313-2.063 2.368-3.234 3.164-1.126.75-2.274 1.125-3.446 1.125-2.203 0-4.43-.328-6.68-.984-2.25-.703-4.03-1.875-5.343-3.516a15.599 15.599 0 01-1.125-1.828c-.328-.75-.61-1.64-.844-2.672-.188-1.078-.305-2.343-.352-3.796 0-1.454.141-3.164.422-5.133a57.313 57.313 0 011.828-8.157c.797-2.671 2.016-5.367 3.657-8.085 1.687-2.72 3.914-5.532 6.68-8.438 2.812-2.953 6.398-6.117 10.757-9.492l4.781 6.75c-2.671 2.016-4.945 3.914-6.82 5.695-1.875 1.735-3.422 3.399-4.64 4.992-1.22 1.547-2.133 3-2.743 4.36-.61 1.312-.984 2.554-1.125 3.726-.187 1.125.117 1.992.914 2.602.797.562 1.852 1.031 3.164 1.406 2.672.703 4.758 1.781 6.258 3.235 1.5 1.406 2.133 3 1.899 4.78z"
        opacity={0.25}
      />
    </svg>
  )
}

export default SvgQuot
