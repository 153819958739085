import * as React from 'react'

function SvgPhone(props) {
  return (
    <svg
      width={9}
      height={14}
      fill="none"
      viewBox="0 0 9 14"
      className=""
      {...props}>
      <path
        fill="#FCFCFC"
        d="M6.3 2.531V8.86H2.7V2.531h3.6zM7.65 0h-6.3C.605 0 0 .567 0 1.266v10.968c0 .7.605 1.266 1.35 1.266h6.3c.745 0 1.35-.567 1.35-1.266V1.266C9 .566 8.395 0 7.65 0zm-6.3 12.656c-.248 0-.45-.19-.45-.422V1.266c0-.232.203-.422.45-.422h6.3c.247 0 .45.19.45.422v10.968c0 .232-.202.422-.45.422h-6.3zM6.862 1.687H2.138c-.186 0-.338.143-.338.317v7.383c0 .174.152.316.338.316h4.724c.186 0 .338-.142.338-.316V2.004c0-.174-.152-.317-.338-.317zm-1.35 9.282H3.489c-.186 0-.338.142-.338.316v.211c0 .174.152.316.337.316h2.026c.185 0 .337-.142.337-.316v-.21c0-.175-.152-.317-.337-.317z"
      />
    </svg>
  )
}

export default SvgPhone
