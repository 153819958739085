import * as React from 'react'

function SvgKonut(props) {
  return (
    <svg viewBox="0 -18 512 511" className="" {...props}>
      <path d="M245.59 118.46c-3.059 0-4.215-.644-12.328-5.698a320.965 320.965 0 01-16.356-10.895c-17.328-12.355-27.937-22.742-32.445-31.754-5.508-11.011-7.145-23.535-4.61-35.261 2.544-11.77 8.934-21.649 17.993-27.82 8.969-6.114 19.492-7.985 30.433-5.407 7.727 1.82 12.61 5.684 17.317 10.406 4.703-4.722 9.586-8.586 17.312-10.406 10.942-2.578 21.465-.707 30.438 5.406 9.054 6.172 15.441 16.051 17.988 27.82 2.531 11.731.895 24.25-4.61 35.262-4.507 9.012-15.12 19.399-32.445 31.754a318.337 318.337 0 01-16.355 10.895c-8.117 5.054-9.27 5.699-12.332 5.699zm-26.684-97.948c-3.547 0-6.828 1.015-9.8 3.043-4.805 3.273-8.25 8.785-9.708 15.52-1.574 7.292-.527 15.136 2.95 22.09 5.539 11.081 31.554 28.62 43.242 35.75 11.687-7.13 37.703-24.669 43.242-35.75 3.477-6.954 4.523-14.798 2.95-22.09-1.458-6.735-4.903-12.247-9.704-15.52-4.273-2.91-9.176-3.739-14.586-2.465-3.722.875-5.617 2.746-11.05 8.523a332.794 332.794 0 01-3.676 3.86c-1.883 1.941-4.473 3.035-7.176 3.035s-5.293-1.094-7.176-3.035a332.803 332.803 0 01-3.676-3.86c-5.433-5.777-7.328-7.648-11.05-8.523a20.807 20.807 0 00-4.782-.578zm0 0M165.7 321.43c-.65 0-1.302-.07-1.95-.2-.64-.132-1.27-.32-1.871-.57a10.83 10.83 0 01-1.73-.922c-.54-.36-1.06-.781-1.52-1.238-.457-.46-.879-.98-1.238-1.52a10.83 10.83 0 01-.922-1.73c-.25-.602-.438-1.23-.57-1.871a10.068 10.068 0 012.73-9.031c.46-.461.98-.868 1.52-1.239a10.83 10.83 0 011.73-.921 9.98 9.98 0 017.652 0c.598.25 1.18.562 1.73.921.54.371 1.06.778 1.52 1.239a10.1 10.1 0 012.93 7.07c0 .66-.07 1.312-.2 1.96-.132.642-.32 1.27-.57 1.872a10.83 10.83 0 01-.921 1.73c-.372.54-.782 1.047-1.239 1.52-.46.457-.98.879-1.52 1.238-.55.36-1.132.672-1.73.922-.601.25-1.23.438-1.87.57-.65.13-1.302.2-1.962.2zm0 0" />
      <path d="M511.984 432.277c-.625-22.718-19.933-41.203-43.046-41.203h-2.903V273.262l2.617 1.922a9.995 9.995 0 0010.438.863 9.998 9.998 0 005.48-8.922v-49.914a9.99 9.99 0 00-4.078-8.059l-134-98.437a9.992 9.992 0 00-11.84 0l-60.136 44.176v-15.504c0-5.524-4.48-10-10-10h-37.852c-5.52 0-10 4.476-10 10v57.996l-16.016 11.77a10 10 0 00-4.078 8.058v19.176C163.828 222.16 115.89 223.184 87 225.535v-9.469c0-5.523-4.477-10-10-10H10c-5.523 0-10 4.477-10 10v159.5c0 5.52 4.477 10 10 10h67c5.523 0 10-4.48 10-10v-24.144h14.559c1.507 3.894 4.336 13.129 7.879 33.71 10.054 58.454 45.59 90.645 100.054 90.645l260.121.067c11.543 0 22.332-4.555 30.383-12.828 8.047-8.27 12.305-19.188 11.988-30.739zm-65.945-41.203h-57.691V290.68c0-5.524-4.48-10-10-10h-75.551c-5.524 0-10 4.476-10 10v24.765l-61.942-54 109.708-80.355 105.472 77.476zm-78.887 0c-2.023-8.984-6.968-17.367-14.66-23.586l-39.695-34.61v-32.194h55.55v90.39zM236.668 149.387h17.848v20.195l-17.848 13.11zm-20.098 72.886l124-91.09 124 91.09v25.098L346.492 160.63a9.967 9.967 0 00-5.922-1.941 9.994 9.994 0 00-5.91 1.933l-118.09 86.496zM67 365.566H20v-139.5h47zm418.66 83.504c-4.25 4.368-9.95 6.774-16.043 6.774l-260.125-.067c-55.879 0-74.535-40.261-80.347-74.035-2.36-13.71-4.75-24.554-7.11-32.222-1.945-6.325-5.566-18.098-15.723-18.098H87v-85.813c28.941-2.441 91.102-4.668 115.758 17.868.054.05.113.105.172.156L339.5 382.69c.105.094.21.184.32.27 9.64 7.715 11.203 21.828 3.492 31.465-3.738 4.668-9.066 7.605-15.011 8.265-5.95.657-11.785-1.039-16.406-4.734l-114.262-92.672c-4.29-3.476-10.586-2.82-14.067 1.469-3.48 4.289-2.824 10.586 1.465 14.066l114.317 92.711c7.59 6.074 16.82 9.301 26.402 9.301 1.578 0 3.164-.09 4.754-.266 11.254-1.246 21.348-6.804 28.422-15.644a42.139 42.139 0 007.937-15.852h102.074c12.383 0 22.723 9.758 23.051 21.754.168 6.11-2.078 11.875-6.328 16.246zm0 0" />
    </svg>
  )
}

export default SvgKonut
