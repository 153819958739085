import * as React from 'react'

function SvgNakliyat(props) {
  return (
    <svg viewBox="0 0 497.051 497.051" className="" {...props}>
      <path d="M471.5 460.251c-8-8-17.6-12-28.8-12h-5.6c-10.4 0-20.8 4-28 12l-13.6 13.6c-4.8 4.8-10.4 7.2-16.8 7.2h-5.6c-6.4 0-12.8-2.4-16.8-7.2l-13.6-13.6c-7.2-7.2-17.6-12-28-12h-5.6c-10.4 0-20.8 4-28 12l-13.6 13.6c-4.8 4.8-10.4 7.2-16.8 7.2h-5.6c-6.4 0-12.8-2.4-16.8-7.2l-13.6-13.6c-7.2-7.2-17.6-12-28-12h-5.6c-10.4 0-20.8 4-28 12l-13.6 13.6c-4.8 4.8-10.4 7.2-16.8 7.2h-5.6c-6.4 0-12.8-2.4-16.8-7.2l-13.6-13.6c-7.2-8-16.8-12-28-12h-4.8c-10.4 0-20.8 4-28 12l-22.4 22.4 11.2 11.2 22.4-22.4c4-4.8 10.4-7.2 16.8-7.2h5.6c6.4 0 12.8 2.4 16.8 7.2l13.6 13.6c7.2 7.2 17.6 12 28 12h5.6c10.4 0 20.8-4 28-12l13.6-13.6c4.8-4.8 10.4-7.2 16.8-7.2h5.6c6.4 0 12.8 2.4 16.8 7.2l13.6 13.6c7.2 7.2 17.6 12 28 12h5.6c10.4 0 20.8-4 28-12l13.6-13.6c4.8-4.8 10.4-7.2 16.8-7.2h5.6c6.4 0 12.8 2.4 16.8 7.2l13.6 13.6c7.2 7.2 17.6 12 28 12h5.6c10.4 0 20.8-4 28-12l13.6-13.6c4.8-4.8 10.4-7.2 16.8-7.2h5.6c6.4 0 12.8 2.4 16.8 7.2l22.4 22.4 11.2-11.2-22.4-22.4zM448.3 304.251h16v16h-16zM416.3 304.251h16v16h-16zM384.3 304.251h16v16h-16zM352.3 304.251h16v16h-16zM320.3 304.251h16v16h-16zM288.3 304.251h16v16h-16zM256.3 304.251h16v16h-16zM224.3 304.251h16v16h-16z" />
      <path d="M488.3 112.251h-24v-24c0-4.8-3.2-8-8-8h-32c-4.8 0-8 3.2-8 8v30.4l-31.2 78.4c0 .8-.8 1.6-.8 3.2v40h-16v-72c0-4.8-3.2-8-8-8h-160c-4.8 0-8 3.2-8 8v72h-8c-4.8 0-8 3.2-8 8v.8c0 32 11.2 63.2 30.4 88.8l59.2 75.2c1.6 1.6 4 3.2 6.4 3.2h4.8l-8.8 8.8c-4.8 4.8-10.4 7.2-16.8 7.2h-5.6c-6.4 0-12.8-2.4-16.8-7.2l-13.6-13.6c-7.2-7.2-17.6-12-28-12h-5.6c-10.4 0-20.8 4-28 12l-13.6 13.6c-4.8 4.8-10.4 7.2-16.8 7.2h-5.6c-6.4 0-12.8-2.4-16.8-7.2l-9.6-8.8 58.4-58.4c3.2-3.2 3.2-8 0-11.2l-64-64c-3.2-3.2-8-3.2-11.2 0l-64 64c-3.2 3.2-3.2 8 0 11.2l42.4 42.4c-10.4 0-20.8 4-28 12l-22.4 22.4 11.2 11.2 22.4-22.4c4.8-4.8 11.2-7.2 17.6-7.2h5.6c6.4 0 12.8 2.4 16.8 7.2l13.6 13.6c7.2 7.2 17.6 12 28 12h5.6c10.4 0 20.8-4 28-12l13.6-13.6c4.8-4.8 10.4-7.2 16.8-7.2h5.6c6.4 0 12.8 2.4 16.8 7.2l13.6 13.6c7.2 7.2 17.6 12 28 12h5.6c10.4 0 20.8-4 28-12l13.6-13.6c4.8-4.8 10.4-7.2 16.8-7.2h5.6c6.4 0 12.8 2.4 16.8 7.2l13.6 13.6c7.2 7.2 17.6 12 28 12h5.6c10.4 0 20.8-4 28-12l13.6-13.6c4.8-4.8 10.4-7.2 16.8-7.2h5.6c6.4 0 12.8 2.4 16.8 7.2l22.4 22.4 11.2-11.2-18.4-18.4h12.8c4.8 0 8-3.2 8-8v-288c0-4.8-3.2-8-8-8zm-416 284.8l-36.8-36.8h36.8v36.8zm0-52.8H35.5l36.8-36.8v36.8zm16-36.8l36.8 36.8H88.3v-36.8zm0 89.6v-36.8h36.8l-36.8 36.8zm344-300.8h16v16h-16v-16zm-100.8 112l20.8-20.8v41.6l-20.8-20.8zm9.6 32h-41.6l20.8-20.8 20.8 20.8zm0-64l-20.8 20.8-20.8-20.8h41.6zm-52.8 11.2l20.8 20.8-20.8 20.8v-41.6zm-36.8 20.8l20.8-20.8v41.6l-20.8-20.8zm9.6 32h-41.6l20.8-20.8 20.8 20.8zm0-64l-20.8 20.8-20.8-20.8h41.6zm-52.8 11.2l20.8 20.8-20.8 20.8v-41.6zm188 237.6c-4.8 4.8-10.4 7.2-16.8 7.2h-5.6c-6.4 0-12.8-2.4-16.8-7.2l-9.6-8.8h57.6l-8.8 8.8zm84-24.8h-204l-12.8-16h216.8v16zm0-32H250.7l-31.2-40c-9.6-12-16-25.6-20.8-40h281.6v80zm0-96H194.7c-.8-5.6-1.6-10.4-2.4-16h288v16zm0-32h-80v-16h80v16zm0-32h-80v-6.4l16.8-41.6h63.2v48zm0-64h-56.8l6.4-16h50.4v16zM88.3 248.251h16c0-31.2 24.8-56 56-56v-16c-40 0-72 32-72 72z" />
      <path d="M120.3 248.251h16c0-13.6 10.4-24 24-24v-16c-22.4 0-40 17.6-40 40zM152.3 64.251c-2.4-20-20-33.6-40-32-3.2 0-6.4 1.6-9.6 2.4-7.2-25.6-33.6-40-59.2-32.8-20.8 5.6-35.2 24.8-35.2 46.4 0 6.4 1.6 12.8 4 19.2-11.2 6.4-15.2 21.6-8.8 32.8 4 7.2 12 12 20.8 12h128c13.6 0 24-10.4 24-24s-10.4-24-24-24zm4.8 30.4c-1.6.8-3.2 1.6-4.8 1.6h-128c-4.8 0-8-4-8-8 .8-4 3.2-7.2 7.2-8 4 .8 8-2.4 8.8-7.2 0-2.4 0-4-1.6-5.6-4-5.6-6.4-12-6.4-19.2 0-17.6 14.4-32 32-32s32 14.4 32 32c0 4.8 3.2 8 8 8 2.4 0 4.8-.8 6.4-2.4 7.2-8 20-8 28-.8 5.6 4.8 7.2 12.8 5.6 19.2-.8 4 1.6 8.8 5.6 9.6 2.4.8 4.8 0 6.4-.8 4-2.4 8.8-1.6 11.2 2.4 2.4 4 1.6 8.8-2.4 11.2z" />
      <path d="M40.3 56.251h16c0-4.8 3.2-8 8-8v-16c-13.6 0-24 10.4-24 24zM296.3 33.851v-1.6c0-17.6-14.4-32-32-32-14.4 0-27.2 9.6-31.2 24-7.2 0-14.4 3.2-19.2 8.8-12.8-3.2-25.6 4.8-28.8 17.6-3.2 12.8 4.8 25.6 17.6 28.8 1.6.8 4 .8 5.6.8h80c13.6 0 24-10.4 24-24 0-10.4-6.4-19.2-16-22.4zm-8 30.4h-80c-4.8 0-8-3.2-8-8s3.2-8 8-8c1.6 0 3.2.8 4.8 1.6 4 2.4 8.8 1.6 11.2-2.4 0-.8.8-.8.8-1.6 1.6-4 6.4-6.4 10.4-4.8.8 0 .8 0 1.6.8 4 2.4 8.8.8 11.2-2.4V32.251c0-8.8 7.2-16 16-16s16 7.2 16 16c0 1.6 0 4-.8 5.6-.8 2.4 0 5.6 1.6 8 1.6 1.6 4 2.4 7.2 2.4 4.8 0 8 3.2 8 8s-3.2 8-8 8zM495.5 17.851c-4-12.8-17.6-20-29.6-16-8 2.4-13.6 8-16 16-12.8 4-20 17.6-16 29.6 2.4 9.6 12 16.8 22.4 16.8 10.4 0 20-7.2 23.2-16.8 12.8-4 20-17.6 16-29.6zm-23.2 14.4c-4.8 0-8 3.2-8 8s-3.2 8-8 8-8-3.2-8-8 3.2-8 8-8 8-3.2 8-8 3.2-8 8-8 8 3.2 8 8-3.2 8-8 8z" />
    </svg>
  )
}

export default SvgNakliyat
