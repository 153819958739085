import * as React from 'react'

function SvgTwitter(props) {
  return (
    <svg
      width={18}
      height={15}
      fill="none"
      viewBox="0 0 18 15"
      className=""
      {...props}>
      <path
        fill="#FCFCFC"
        d="M16.15 3.643c.011.16.011.32.011.48C16.161 9 12.45 14.62 5.665 14.62c-2.09 0-4.032-.605-5.665-1.656.297.035.582.046.89.046a7.389 7.389 0 004.58-1.576 3.696 3.696 0 01-3.448-2.559c.228.035.456.057.696.057.332 0 .663-.045.971-.125a3.69 3.69 0 01-2.958-3.62V5.14a3.715 3.715 0 001.667.468A3.687 3.687 0 01.754 2.536c0-.686.182-1.314.502-1.862a10.486 10.486 0 007.607 3.86 4.164 4.164 0 01-.091-.845A3.687 3.687 0 0112.46 0a3.68 3.68 0 012.695 1.165 7.264 7.264 0 002.341-.89 3.68 3.68 0 01-1.621 2.032A7.396 7.396 0 0018 1.737a7.932 7.932 0 01-1.85 1.906z"
        opacity={0.9}
      />
    </svg>
  )
}

export default SvgTwitter
