import React, { useState } from 'react'
import { Link } from 'gatsby'
import style from './header.module.css'
//import { globalHistory } from '@reach/router'
import { useScrollPosition } from 'react-use-scroll-position'
import c from 'classnames'
import { Hamburger, Close } from './icons'
import Logo from './logo'

// function trailingSlashes(pathname) {
//   const hasSlashes = pathname.split('/').length > 2
//   return hasSlashes ? pathname.slice(0, -1) : pathname
// }

function Header({ pages }) {
  // const location = globalHistory.location.pathname
  const [isShow, setShow] = useState(false)
  const { y } = useScrollPosition()
  // const activePage = MENU.find((_) => _.url === trailingSlashes(location))

  const onScroll = y > 80 / 2 ? style.onScroll : null
  const mobileMenuShow = isShow ? style.mobileMenuShow : null
  const isActive = isShow ? style.isActive : null

  const onToggle = () => setShow(!isShow)

  return (
    <header className={c(style.header, mobileMenuShow, onScroll)}>
      <div className="container d-flex align-items-center justify-content-between">
        <Logo />

        <button
          onClick={onToggle}
          title="Mobile Menü"
          value="Mobile Menü"
          aria-label="Mobile Menü"
          className={c(
            style.mobileMenuIcon,
            isActive,
            'd-inline-flex d-lg-none'
          )}>
          {/*<span className={style.hamburger}>*/}
          {/*  <Hamburger />*/}
          {/*</span>*/}
          {/*<span className={style.close}>*/}
          {/*  <Close />*/}
          {/*</span>*/}
        </button>
        <nav aria-label="Üst Menü">
          {pages.map((page) => {
            if (page.hidden === 'desktop') {
              return null
            }
            return (
              <Link
                key={page.id}
                to={page.url}
                activeClassName="active"
                className={style.link}>
                {page.name}
              </Link>
            )
          })}
        </nav>
      </div>
      <div className={style.mobileMenu}>&nbsp;</div>
    </header>
  )
}
export default Header
