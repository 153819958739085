import * as React from 'react'

function SvgClose(props) {
  return (
    <svg width={22} height={22} viewBox="0 0 16 16" className="" {...props}>
      <g fill="#FFF">
        <rect
          width={20}
          height={2.5}
          x={-2.045}
          y={7}
          rx={1.25}
          transform="rotate(45 7.955 8.25)"
        />
        <rect
          width={20}
          height={2.5}
          x={-2.045}
          y={7}
          rx={1.25}
          transform="rotate(135 7.955 8.25)"
        />
      </g>
    </svg>
  )
}

export default SvgClose
