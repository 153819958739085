import React from 'react'
import { Link } from 'gatsby'
import styles from './app-menu.module.css'
import * as icons from './icons'

function AppMenu({ pages }) {
  return (
    <div className={styles.appMenu}>
      {pages.map((page) => {
        if (page.hidden === 'mobile') {
          return null
        }
        const link =
          page.url.indexOf('http') >= 0 ? (
            <a
              key={page.id}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.link}
              href={page.url}>
              {React.createElement(icons[page.icon])}
              {page.name}
            </a>
          ) : (
            <Link
              key={page.id}
              to={page.url}
              activeClassName="active"
              className={styles.link}>
              {React.createElement(icons[page.icon])}
              {page.name}
            </Link>
          )
        return link
      })}
    </div>
  )
}
export default AppMenu
