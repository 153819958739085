import * as React from 'react'

function SvgKasko(props) {
  return (
    <svg width={512} height={512} viewBox="0 0 60 60" className="" {...props}>
      <g fill="#000" fillRule="nonzero">
        <path d="M36.608 26.523l7.812 5.561a1 1 0 001.16 0l7.812-5.561A6.18 6.18 0 0056 21.509V9.677a1 1 0 00-.6-.918l-10-4.32a1 1 0 00-.792 0l-10 4.32a1 1 0 00-.6.918v11.832a6.181 6.181 0 002.6 5.014zM36 10.334l9-3.887 9 3.887v11.175a4.178 4.178 0 01-1.769 3.391L45 30.042l-7.231-5.148A4.175 4.175 0 0136 21.509V10.334z" />
        <path d="M42.4 23.8a1 1 0 001.381-.175l8-10a1 1 0 10-1.562-1.25l-7.4 9.244L39.6 19.2a1 1 0 10-1.2 1.6l4 3z" />
        <path d="M2 53h1v4a3 3 0 003 3h6a3 3 0 003-3v-4h24v4a3 3 0 003 3h6a3 3 0 003-3v-4h1a2 2 0 002-2V36a4.947 4.947 0 00-1.729-3.77l3.44-2.448A10.188 10.188 0 0060 21.509V7.048a1 1 0 00-.6-.918L45.4.082a1 1 0 00-.792 0L30.6 6.13a1 1 0 00-.6.918V11H14.87a5 5 0 00-4.714 3.338l-5.89 16.736A4.993 4.993 0 000 36v15a2 2 0 002 2zm11 4a1 1 0 01-1 1H6a1 1 0 01-1-1v-4h8v4zm-4-6H7v-2h2v2zm40 6a1 1 0 01-1 1h-6a1 1 0 01-1-1v-4h8v4zm-2-6h-2v-2h2v2zM32 7.705l13-5.616 13 5.616v13.8a8.183 8.183 0 01-3.449 6.644L45 34.952l-9.551-6.8A8.183 8.183 0 0132 21.509V7.705zM47 37a1 1 0 011 1v2a1 1 0 01-1 1h-6a1 1 0 01-1-1 3 3 0 013-3h4zM30 17v2h-7v-2h7zm0-2h-2v-2h2v2zm-17.957 0a3 3 0 012.827-2H26v2h-3a2 2 0 00-2 2v2a2 2 0 002 2h7v.509a10.188 10.188 0 004.289 8.273L36 31H6.412l5.631-16zM2 36a3 3 0 013-3h33.81l3.022 2.152A5 5 0 0038 40a3 3 0 003 3h6a3 3 0 003-3v-2a3 3 0 00-1.915-2.789l2.476-1.763A2.958 2.958 0 0152 36v15h-3v-2a2 2 0 00-2-2h-2a2 2 0 00-2 2v2H11v-2a2 2 0 00-2-2H7a2 2 0 00-2 2v2H2V36z" />
        <path d="M18 15h-1.711a3.007 3.007 0 00-2.83 2L9.705 27.668a1 1 0 001.887.664l3.754-10.664a1 1 0 01.943-.668H18a1 1 0 000-2zM7 43h6a3 3 0 003-3 5.006 5.006 0 00-5-5H7a3 3 0 00-3 3v2a3 3 0 003 3zm-1-5a1 1 0 011-1h4a3 3 0 013 3 1 1 0 01-1 1H7a1 1 0 01-1-1v-2zM20 49h14a2 2 0 002-2v-4a2 2 0 00-2-2H20a2 2 0 00-2 2v4a2 2 0 002 2zm0-6h14v4H20v-4z" />
      </g>
    </svg>
  )
}

export default SvgKasko
