import * as React from 'react'

function SvgSaglik(props) {
  return (
    <svg width={512} height={512} viewBox="0 0 60 60" className="" {...props}>
      <g fill="#000" fillRule="nonzero">
        <path d="M12.542 48.64c-.347.368-.54.854-.542 1.36v8a2 2 0 002 2h11a2 2 0 002-2v-8a2 2 0 00-2-2v-1.861a10.478 10.478 0 00-2.192-6.55 7.2 7.2 0 00-3.333-2.431 19.782 19.782 0 01-5.725-3.674 4.6 4.6 0 00-5.465-.284l-2.163-5.054a3.188 3.188 0 10-5.833 2.575l3.919 8.621a10.621 10.621 0 002.171 3.134l6.163 6.164zM25 58H14v-8h11v8zM2.109 29.894a1.189 1.189 0 112.174-.96l2.237 5.217a11.8 11.8 0 002.522 3.725l4.568 4.569a1 1 0 101.414-1.414l-4.568-4.569a9.863 9.863 0 01-1.233-1.5 2.6 2.6 0 013.239.054 21.321 21.321 0 006.38 4.04 5.253 5.253 0 012.405 1.783 8.485 8.485 0 011.753 5.3V48h-8.269l-6.938-6.938a8.649 8.649 0 01-1.765-2.547l-3.919-8.621z" />
        <path d="M22 53h-1a1 1 0 000 2h1a1 1 0 000-2zM56.809 26.213c-1.276 0-2.429.76-2.93 1.933l-2.158 5.035a4.612 4.612 0 00-5.471.3 19.782 19.782 0 01-5.725 3.674 7.2 7.2 0 00-3.333 2.431A10.478 10.478 0 0035 46.139V48a2 2 0 00-2 2v8a2 2 0 002 2h11a2 2 0 002-2v-8a1.988 1.988 0 00-.542-1.36l6.163-6.164a10.621 10.621 0 002.171-3.134l3.919-8.621a3.187 3.187 0 00-2.9-4.508h-.002zM46 58H35v-8h11v8zm11.891-28.106l-3.919 8.621a8.649 8.649 0 01-1.765 2.547L45.269 48H37v-1.861a8.485 8.485 0 011.753-5.3 5.256 5.256 0 012.4-1.783 21.312 21.312 0 006.381-4.04 2.6 2.6 0 013.245-.063 9.806 9.806 0 01-1.239 1.51l-4.568 4.569a1 1 0 001.414 1.414l4.568-4.569a11.8 11.8 0 002.522-3.725l2.237-5.218a1.187 1.187 0 012.091-.175c.22.34.25.768.083 1.136l.004-.001z" />
        <path d="M39 53h-1a1 1 0 000 2h1a1 1 0 000-2zM41.074 0A13.519 13.519 0 0030 5.649 13.519 13.519 0 0018.926 0C10.953 0 6 5.053 6 13.188A16.388 16.388 0 007 19H2a1 1 0 000 2h5.916a24.1 24.1 0 005.438 6.193c.512.456 1.009.9 1.474 1.343 3.4 3.241 14.09 11.876 14.544 12.242a1 1 0 001.256 0c.454-.366 11.145-9 14.544-12.242.465-.443.962-.887 1.474-1.343A24.1 24.1 0 0052.084 21H58a1 1 0 000-2h-4.995c.68-1.861 1.017-3.83.995-5.812C54 5.053 49.047 0 41.074 0zM8 13.188C8 6.182 12.084 2 18.926 2a11.621 11.621 0 0110.188 5.964 1.041 1.041 0 001.772 0A11.621 11.621 0 0141.074 2C47.916 2 52 6.182 52 13.188c.03 1.998-.367 3.98-1.165 5.812H45a1 1 0 00-.925.621l-1.114-3.9a1 1 0 00-.955-.721H42a1 1 0 00-.958.713l-1.88 6.268-2.184-10.19a1 1 0 00-1.948-.034L33.219 19H28a1 1 0 00-.925.621l-1.114-3.9a1 1 0 00-.955-.721.945.945 0 00-.964.713l-1.88 6.268-2.184-10.19a1 1 0 00-.961-.791.977.977 0 00-.987.757L16.219 19H9.165A14.029 14.029 0 018 13.188zM45.314 25.7c-.528.472-1.042.93-1.523 1.389C40.918 29.83 32.39 36.775 30 38.713c-2.39-1.938-10.917-8.883-13.791-11.624-.481-.459-.995-.917-1.523-1.389a25.3 25.3 0 01-4.415-4.7H17a1 1 0 00.97-.757l.955-3.82 2.1 9.786a1 1 0 00.938.79c.455.014.864-.277 1-.712l2.019-6.729 1.062 3.717a1 1 0 00.94.725c.434-.004.82-.277.97-.684L28.721 21H34a1 1 0 00.97-.757l.955-3.82 2.1 9.786a1 1 0 00.938.79H39a1 1 0 00.958-.713l2.019-6.729 1.062 3.717a1 1 0 00.94.725c.433-.008.817-.279.97-.684L45.721 21h4.008a25.3 25.3 0 01-4.415 4.7z" />
      </g>
    </svg>
  )
}

export default SvgSaglik
