import * as React from 'react'

function SvgFacebook(props) {
  return (
    <svg
      width={18}
      height={18}
      fill="none"
      viewBox="0 0 18 18"
      className=""
      {...props}>
      <path
        fill="#FCFCFC"
        d="M18 .992v16.012c0 .55-.446.992-.992.992h-4.589v-6.967h2.339l.35-2.716h-2.693V6.577c0-.787.217-1.322 1.346-1.322H15.2v-2.43c-.25-.033-1.101-.109-2.098-.109-2.073 0-3.495 1.266-3.495 3.592v2.005H7.26v2.716h2.347V18H.992A.995.995 0 010 17.008V.992C0 .446.446 0 .992 0h16.012c.55 0 .996.446.996.992z"
        opacity={0.9}
      />
    </svg>
  )
}

export default SvgFacebook
