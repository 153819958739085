import * as React from 'react'

function SvgEgitim(props) {
  return (
    <svg
      width={512}
      height={512}
      data-name="Layer 1"
      viewBox="0 0 512 512"
      className=""
      {...props}>
      <path d="M187.854 502a130.491 130.491 0 00125.806-95.173 37.184 37.184 0 000-71.041 129.928 129.928 0 00-9.113-23.363 5.988 5.988 0 00.684-2.773v-65.64l32.985-12.692v54.859a18.7 18.7 0 1012-.717V226.7l3.155-1.214c7.716-2.967 8.868-7.717 8.868-10.284s-1.152-7.316-8.868-10.284L202.234 146.77c-8.036-3.092-20.626-3.092-28.661 0L22.438 204.919c-7.716 2.968-8.868 7.718-8.868 10.284s1.152 7.317 8.868 10.284l46.26 17.8v66.363a6 6 0 001.741 4.226 130.013 130.013 0 00-8.395 21.948 37.177 37.177 0 000 70.965A130.477 130.477 0 00187.854 502zm157.428-191.747a6.739 6.739 0 116.691-6.739 6.723 6.723 0 01-6.691 6.739zm-167.4-152.284c5.338-2.053 14.7-2.053 20.043 0L346.683 215.2l-148.758 57.238c-5.338 2.053-14.7 2.053-20.042 0L29.125 215.2zm-4.308 125.669a45.453 45.453 0 0028.66 0l91-35.012V305.3c-67.734 21.65-144.853 21.674-212.534-.011V247.9zM47.962 371.307a25.216 25.216 0 0120.161-24.668 6 6 0 004.635-4.418 117.893 117.893 0 018.949-24.053 362.894 362.894 0 00105.224 15.364 363.514 363.514 0 00106.84-15.832 117.9 117.9 0 019.18 24.494 6 6 0 004.653 4.42 25.178 25.178 0 010 49.379 6 6 0 00-4.653 4.42 118.726 118.726 0 01-230.194-.023 6 6 0 00-4.634-4.418 25.219 25.219 0 01-20.161-24.665z" />
      <path d="M249.564 386.933a18.892 18.892 0 10-18.891-18.892 18.913 18.913 0 0018.891 18.892zm0-25.784a6.892 6.892 0 11-6.891 6.892 6.9 6.9 0 016.891-6.892zM126.244 382.683a18.892 18.892 0 10-18.891-18.892 18.913 18.913 0 0018.891 18.892zm0-25.783a6.892 6.892 0 11-6.891 6.891 6.9 6.9 0 016.891-6.891zM134.19 402.906a6 6 0 00-6 6 59.714 59.714 0 00119.427 0 6 6 0 00-12 0 47.714 47.714 0 01-95.427 0 6 6 0 00-6-6zM495.074 49.884l-80.036-39.271a6 6 0 00-5.28 0L329.723 49.77a6 6 0 00-3.364 5.389v74.92c0 8.4 2.289 17.764 6.62 27.083 5.542 11.929 13.81 22.237 22.681 28.279l53.357 36.359a6 6 0 006.756 0l53.357-36.354c16.43-11.192 29.3-35.51 29.3-55.363V55.271a6 6 0 00-3.356-5.387zm-8.643 80.2c0 15.751-11.017 36.563-24.057 45.445L412.4 209.576l-49.979-34.052c-7.134-4.859-13.9-13.4-18.555-23.419-3.548-7.635-5.5-15.458-5.5-22.026V58.9l74.032-36.221 74.04 36.328z" />
      <path d="M382.719 109.784a6 6 0 10-8.379 8.59l24.176 23.58a6 6 0 008.379 0l43.555-42.481a6 6 0 10-8.379-8.59l-39.366 38.395z" />
    </svg>
  )
}

export default SvgEgitim
