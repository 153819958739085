import React from 'react'
import { StaticQuery, graphql,Link } from 'gatsby'
import Img from 'gatsby-image'
import styles from './guarantee.module.css'
import { Quot } from './icons'
function Guarantee() {
  return (
    <StaticQuery
      query={graphql`
        {
          guarantee: file(name: { eq: "guarantee" }) {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={(data) => (
        <section role="main" aria-labelledby="guaranteTitle" className={styles.guarantee}>
          <div className="container">
            <div className="row">
              <div className="col-md-7 col-lg-5">
                <h2 id="guaranteTitle">Neden Timurlar Çatalca Sigorta</h2>
                <p className="hero">
                  Taleplerinizi, isteklerinizi ve beklentilerinizi önemsiyoruz.

                </p>
                <p>
                  Bu doğrultuda değerli müşterilerimizin tercihleri
                  doğrultusunda hizmet vermek adına empati kuruyor, kendimizi
                  müşterilerimizin yerine koyuyoruz. Kaliteli, hızlı ve aktif
                  hizmet başta olmak üzere; müşterilerinin istediğini sunan bu
                  sayede memnun eden bir sigortacılık mottosu ile hareket
                  ediyoruz.
                </p>
                <div className="d-flex justify-content-center justify-content-md-start">
                  <Link to="/hakkimizda" className="btn btn-primary mx-auto ml-md-0">
                    Daha Fazla
                  </Link>
                </div>
              </div>
              <div className={styles.quot}>
                <Quot className="mb-3 mb-lg-5" />
                7/24 kaliteli hizmet anlayışı ile her zaman sizinleyiz
                <Quot className={styles.quotBottom} />
              </div>
              <div className="col-md-5 col-lg-5 offset-lg-1 mt-4 mt-md-0">
                <Img fluid={data.guarantee.childImageSharp.fluid} />
              </div>
            </div>
          </div>
        </section>
      )}
    />
  )
}
export default Guarantee
