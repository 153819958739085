import React, { useState } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styles from './comments.module.css'
import BackgroundImage from 'gatsby-background-image'
import Slider from 'react-slick'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { Quot, Star } from '../icons'
function Comments() {
  const [avatarBig, setBigAvatar] = useState(0)

  return (
    <StaticQuery
      query={graphql`
        {
          comments: allMarkdownRemark(
            filter: { fileAbsolutePath: { regex: "/home/comments/" } }
            limit: 8
          ) {
            edges {
              node {
                id
                frontmatter {
                  date
                  comment
                  name
                  stars
                  url
                  featuredImage {
                    childImageSharp {
                      fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
          }
          metaData:site {
            siteMetadata {
              title
              description
              telephone
              url
              address{
              streetAddress
              }
              organization {
                name
              }
              insuranceSchema {
                currenciesAccepted
                priceRange
              }
            }
          }
        }
      `}
      render={(data) => {
        const avatar = [...data.comments.edges].slice(
          1,
          data.comments.edges.length
        )
        var settings = {
          dots: true,
          infinite: true,
          speed: 500,
          slidesToShow: true,
          slidesToScroll: true,
          adaptiveHeight: true,
          afterChange: (state) => {
            setBigAvatar(state)
          }
        }
        console.log(data.metaData)
        return (
          <section
            role="main"
            aria-label="Google Müşteri Yorumları"
            className={styles.comments}>
            <div
              className="comments container position-relative"
              itemscope
              itemtype="https://schema.org/Review">
              <div className={styles.commentsHead}>
                <Quot className={styles.svg} />
                <BackgroundImage
                  Tag="div"
                  className={styles.avatarBig}
                  fluid={
                    data.comments.edges[avatarBig].node.frontmatter
                      .featuredImage.childImageSharp.fluid
                  }
                />
              </div>

              <div className="avatar-parent">
                {avatar.map(({ node }) => (
                  <BackgroundImage
                    Tag="div"
                    key={node.id}
                    className={styles.avatar}
                    fluid={node.frontmatter.featuredImage.childImageSharp.fluid}
                  />
                ))}
              </div>
              <div className={styles.stars}>
                <Star id={`star1`} />
                <Star id={`star2`} />
                <Star id={`star3`} />
                <Star id={`star4`} />
                <Star id={`star5`} />
              </div>
              <Slider {...settings}>
                {data.comments.edges.map(({ node }) => (
                  <div key={node.id}>
                    <div className="col-md-10 mx-auto">
                      <div className={styles.comment}>
                        {node.frontmatter.comment}
                      </div>
                    </div>
                    <div className={styles.commentFoot}>
                      <span className="h6">
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={node.frontmatter.url}>
                          {node.frontmatter.name}
                        </a>
                      </span>
                      •<span className="h6">{node.frontmatter.date}</span>
                    </div>
                    <div
                      className="d-none"
                      itemScope
                      itemType="https://schema.org/Review">
                      <div
                        itemProp="itemReviewed"
                        itemScope
                        itemType="https://schema.org/InsuranceAgency">
                        <img
                          itemProp="image"
                          src={'../../logo.png'}
                          alt={node.frontmatter.name}
                        />
                        <span itemProp="name">{data.metaData.siteMetadata.organization.name}</span>
                        <span itemProp="priceRange">{data.metaData.siteMetadata.insuranceSchema.priceRange}</span>
                        <span itemProp="telephone">{data.metaData.siteMetadata.telephone}</span>
                        <span itemProp="address">{data.metaData.siteMetadata.address.streetAddress}</span>
                      </div>
                      <span
                        itemProp="reviewRating"
                        itemScope
                        itemType="https://schema.org/Rating">
                        <span itemProp="ratingValue">5</span>
                      </span>
                      <span itemProp="name">{node.frontmatter.name}</span>
                      <span
                        itemProp="author"
                        itemScope
                        itemType="https://schema.org/Person">
                        <span itemProp="name">{node.frontmatter.name}</span>
                      </span>
                      <span itemProp="reviewBody">
                        {node.frontmatter.comment}
                      </span>
                      <div
                        itemProp="publisher"
                        itemScope
                        itemType="https://schema.org/Organization">
                        <span itemprop="name">Google Business</span>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </section>
        )
      }}
    />
  )
}
export default Comments
