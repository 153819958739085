/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import path from 'path'
import SchemaOrg from './SchemaOrg'

function SEO({
  description = '',
  lang = 'tr',
  meta = [],
  title,
  isBlogPost = false,
  postImage,
  datePublished
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            url
            author {
              name
              twitter
            }
            socialLinks {
              instagram
              facebook
              twitter
              linkedin
            }
            organization {
              name
              logo
            }
            address {
              addressLocality
              postalCode
              streetAddress
              lat
              long
            }
            telephone
            mobilePhone
            mailAddress
            insuranceSchema {
              currenciesAccepted
              openingHours
              paymentAccepted
              priceRange
            }
            officeImage
          }
        }
      }
    `
  )

  const metaData = site.siteMetadata

  const metaTitle =
    title != '' ? `${title} | ${metaData.title}` : metaData.title
  const metaDescription = description || site.siteMetadata.description
  const url = `${metaData.url}${path.sep}`
  const canonicalUrl = `${metaData.url}${path.sep}`
  const image = postImage || metaData.organization.logo
  const metaPublished = isBlogPost ? datePublished : false
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang
        }}
        title={metaTitle}
        titleTemplate={`%s | ${metaData.title}`}
        meta={[
          { name: 'robots', content: 'index, follow' },
          {
            name: `description`,
            content: metaDescription
          },
          {
            property: `og:title`,
            content: metaTitle
          },
          {
            property: `og:description`,
            content: metaDescription
          },
          {
            property: `og:type`,
            content: `website`
          },
          {
            property: `og:url`,
            content: url
          },
          {
            name: `twitter:card`,
            content: `summary`
          },
          {
            name: `twitter:creator`,
            content: metaData.author.twitter
          },
          {
            name: `twitter:title`,
            content: `${title} | ${metaData.title}`
          },
          {
            name: `twitter:description`,
            content: metaDescription
          },
          {
            name: `og:locale`,
            content: 'tr_TR'
          },         
          {
            name: `yandex-verification`,
            content: 'e26c94b1606a9901'
          }
        ].concat(meta)}
      />
      <SchemaOrg
        author={metaData.author}
        isBlogPost={isBlogPost}
        url={url}
        title={metaTitle}
        image={image}
        description={description}
        datePublished={metaPublished}
        canonicalUrl={canonicalUrl}
        organization={metaData.organization}
        insuranceSchema={metaData.insuranceSchema}
        socialLinks={metaData.socialLinks}
        defaultTitle={title}
        officeImage={metaData.officeImage}
        address={metaData.address}
        telephone={metaData.telephone}
        mobilePhone={metaData.mobilePhone}
        mailAddress={metaData.mailAddress}
      />
    </>
  )
}

export default SEO
