import * as React from 'react'

function SvgHamburger(props) {
  return (
    <svg
      width={28}
      height={28}
      fill="none"
      viewBox="0 0 28 17"
      className=""
      {...props}>
      <path
        fill="#FCFCFC"
        d="M.005.5h27v2h-27zM.005 7.5h27v2h-27zM9.005 14.5h18v2h-18z"
      />
    </svg>
  )
}

export default SvgHamburger
