import * as React from 'react'

function SvgStar(props) {
  return (
    <svg
      width={20}
      height={20}
      fill="none"
      viewBox="0 0 20 20"
      className=""
      {...props}>
      <path fill="url(#star_svg__pattern0)" d="M0 0h20v20H0z" />
      <defs>
        <pattern
          id="star_svg__pattern0"
          width={1}
          height={1}
          patternContentUnits="objectBoundingBox">
          <use
            transform="matrix(.0204 0 0 .0204 0 -.153)"
            xlinkHref="#star_svg__image0"
          />
        </pattern>
        <image
          id="star_svg__image0"
          width={49}
          height={64}
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADEAAABACAYAAACz4p94AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEwAACxMBAJqcGAAAA+FJREFUaIHtmE9oHHUUx7/vzUwColWTzW6k9tCiCFZK6UUpZvEqFrKTDYEihVLEak8e6ll78CLFi9IawV7spZo/G6QBQSlkKSj2INa/oFAQEnbXFVoLpTsz7/WQWEp3k53f7O8XFOdzm/nNe9/3nTe/3/xmgJycnJycnJycnH8NOlfc41qDXSbXueKeyNNvZam026WOUxOJhw8YPCKi77vUcWYiqo1PA/Ti+hG9FNWKoSstcpFUaqMPxeL/zISdd8+J/BEkeJpmWjdt6znpRCL+qXsNAAAz74p9ftuFnvVO3F4c20/gKwx494+JIFbWA8Nh86pNTaudUAV54LO9DAAAM3xP6ENVuzfPqomkVnwVwHN9FA8mtdIxm7rW7ojMl4ox9BdmerTvtZC2r8FTPLXatqFtrRMx4XQaA+uiPJpQ/K4tbSudiBZLLxBwySRGBAqViaHp1uVB9TObkM9Hdkrkl5VoQoGQgXHjHMAaqS6SUp29eIUr7dUstaQ2IXOFJ4W9CSEtk2iZmK3vhxT4HaJ18miFSeo82fotTVxPE/oWuHOg+IyXUBmkZQFNZLnTgyKCVZDUWbmesK4MVZo/EEHvv67LRLRQek9Vj6adpNuJQP4i5XPBVOPNe893rU4ee7PbV5Yx6nnSVV+XCa6s/koeVQWItqeudAikA/hhr3nS8z0RVBqXWPGa+9LSw8ArQ+FafZOx3vhTjXMqau2FNAgKvOOHrU82G99yiVUFJQulz8Co2i8tHaJyIQhbh3utSv+w5baDCMpxcASqV+yXl4qvg+sPHN3KAJDyZSfzhcdi0DfMvMtObSk0Ra754Ge52mj2uzbVBpCrf66pT4dE8Pfg5fVHBDfU50NpDAAGu9jhyeb3BBwWIMleXn8ESIhlZniy8WPaGKOteFBtXGToSfPS0sOKN4Kw9YVRjKlIAnxlGmOCJ/ylaYyxCSYqmMaYEHFsnD/Dlx2NmscYZM+Q39gEiTjtBGXodJZvbKedyJLf2ITC7ZxQqPtOKMGpCah5fvPVCfrfn9hI2W4BBNCLgB6H6vL6cZr05p3wTQNUUKAtrAukRaCPA9Ashc1rG6c/kqXS7iTR40p6jMFjm+ZncxPG/50686XrzNjRY+gyoGe8qDBHMz91NouX5SeG5daNaYBOgHGwaxzSHgpbRkaMTOgsgqRYulugADcJOC/QM1l+199eKu7jhE4o4WUGHtzIKcF3jYBOpXz8YGri07HxJOA1CK6C5CyznOdKe+DtuSyP7JBbwRGQvA7ivaxewdbP5m6xC4+PdBbGnneSfINObbysiw8/4lIjJycnJycnJ+f/zB0tqlhzRFLUmwAAAABJRU5ErkJggg=="
        />
      </defs>
    </svg>
  )
}

export default SvgStar
