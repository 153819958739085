import * as React from 'react'

function SvgYoutube(props) {
  return (
    <svg
      width={26}
      height={18}
      fill="none"
      viewBox="0 0 26 18"
      className=""
      {...props}>
      <path
        fill="#FCFCFC"
        d="M25.065 2.816A3.217 3.217 0 0022.802.538C20.805 0 12.8 0 12.8 0S4.795 0 2.798.538A3.217 3.217 0 00.535 2.816C0 4.826 0 9.018 0 9.018s0 4.193.535 6.202c.294 1.109 1.162 1.945 2.263 2.242C4.795 18 12.8 18 12.8 18s8.005 0 10.002-.538c1.101-.297 1.969-1.133 2.263-2.242.535-2.01.535-6.202.535-6.202s0-4.192-.535-6.202zM10.182 12.825V5.212l6.69 3.806-6.69 3.807z"
        opacity={0.9}
      />
    </svg>
  )
}

export default SvgYoutube
