import React from 'react'
import { Helmet } from 'react-helmet'
const SchemaOrg = ({
  author,
  canonicalUrl,
  datePublished,
  defaultTitle,
  description,
  image,
  isBlogPost,
  organization,
  title,
  url,
  insuranceSchema,
  socialLinks,
  officeImage,
  address,
  telephone,
  mobilePhone,
  mailAddress
}) => {
  const baseSchema = [
    {
      '@context': 'http://schema.org',
      '@type': 'WebPage',
      url,
      name: title,
      inLanguage: 'tr',
      alternateName: defaultTitle,
      sameAs: Object.values(socialLinks)
    },
    {
      '@context': 'http://schema.org',
      '@type': 'InsuranceAgency',
      url,
      name: title,
      alternateName: defaultTitle,
      currenciesAccepted: insuranceSchema.currenciesAccepted,
      openingHours: insuranceSchema.openingHours,
      priceRange: insuranceSchema.priceRange,
      paymentAccepted: insuranceSchema.paymentAccepted,
      image: Object.values(officeImage),
      geo: {
        '@type': 'GeoCoordinates',
        latitude: address.lat,
        longitude: address.long
      },
      address: {
        '@type': 'PostalAddress',
        addressLocality: address.addressLocality,
        postalCode: address.postalCode,
        streetAddress: address.streetAddress
      },
      contactPoint: [
        {
          '@type': 'ContactPoint',
          telephone: telephone,
          contactType: 'Customer Support'
        },
        {
          '@type': 'ContactPoint',
          telephone: mobilePhone,
          contactType: 'Mobile Phone'
        }
      ],
      email: mailAddress,
      telephone: telephone,
      alumni: [
        {
          '@type': 'Person',
          name: 'İlknur Bekci Yılmaz'
        }
      ],
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '5',
        reviewCount: '56'
      },
      brand: 'Ak Sigorta,Allianz,Anadolu Sigorta,Ankara Sigorta, Atlas Mutuel, Corpus Sigorta,Doğa Sigorta,Eureko Sigorta,Generali' +
          ' Sigorta,Groupama,Gig,Güneş Sigorta,Halk sigorta,Hdi,Koru Mutuel Sigorta,Neova,Orient,Quick Sigorta,Ray Sigorta,Sompo Japan Sigorta,' +
          ' Şeker Sigorta, Unico Sigorta, Tmt Sigorta, Türk Nippon Sigorta, Aegon Sigorta, Avivasa, Allianz Emeklilik, Bereket Emeklilik, Demir' +
          ' Hayat',
      employee: {
        jobTitle: 'Sigortacı',
        image: `${organization.url}/ilknuryilmaz.jpg`,
      },
      owns: {
        name: 'İlknur Yılmaz'
      },
      sameAs: Object.values(socialLinks),
      logo: {
        '@type': 'ImageObject',
        url: image
      }
    }
  ]

  const schema = isBlogPost
    ? [
        ...baseSchema,
        {
          '@context': 'http://schema.org',
          '@type': 'BlogPosting',
          url,
          name: title,
          alternateName: defaultTitle,
          headline: title,
          image: {
            '@type': 'ImageObject',
            url: image
          },
          description,
          author: {
            '@type': 'Person',
            name: author.name
          },
          publisher: {
            '@type': 'Organization',
            url: organization.url,
            logo: `${organization.url}/${organization.logo}`,
            name: organization.name
          },
          mainEntityOfPage: {
            '@type': 'WebSite',
            '@id': canonicalUrl
          },
          datePublished
        }
      ]
    : baseSchema
  return (
    <Helmet>
      {/* Schema.org tags */}
      <script type="application/ld+json">{JSON.stringify(schema)}</script>
    </Helmet>
  )
}

export default SchemaOrg
