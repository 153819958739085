import * as React from 'react'

function SvgCampaign(props) {
  return (
    <svg viewBox="0 0 512.061 512.061" className="" {...props}>
      <path d="M510.991 314.59l-22.496-58.56 22.464-58.592c2.496-6.464.512-13.824-4.864-18.176l-48.736-39.488-9.856-61.984a16.033 16.033 0 00-13.28-13.28l-61.984-9.856-39.488-48.736c-4.352-5.376-11.68-7.328-18.176-4.864l-58.56 22.496-58.592-22.464a15.91 15.91 0 00-18.144 4.864l-39.488 48.736-61.984 9.856a16.033 16.033 0 00-13.28 13.28l-9.856 61.984-48.736 39.488c-5.376 4.352-7.328 11.68-4.864 18.144l22.464 58.592-22.464 58.592c-2.464 6.464-.512 13.792 4.864 18.144l48.736 39.488 9.856 61.984a16.033 16.033 0 0013.28 13.28l61.984 9.856 39.488 48.736c4.384 5.408 11.712 7.392 18.176 4.864l58.56-22.464 58.592 22.464a16.195 16.195 0 005.728 1.056c4.704 0 9.344-2.08 12.448-5.92l39.488-48.736 61.984-9.856a16.033 16.033 0 0013.28-13.28l9.856-61.984 48.736-39.488c5.376-4.352 7.328-11.712 4.864-18.176zm-54.624-52.832l20.512 53.44-44.512 36.032c-3.072 2.496-5.12 6.016-5.728 9.92l-8.992 56.544-56.544 8.992c-3.904.608-7.424 2.656-9.92 5.728l-35.968 44.512-53.44-20.512c-1.856-.672-3.808-1.056-5.76-1.056s-3.904.352-5.728 1.056l-53.44 20.512-36.032-44.512c-2.496-3.072-6.016-5.12-9.92-5.728l-56.544-8.992-8.992-56.544c-.608-3.904-2.656-7.424-5.728-9.92l-44.512-36 20.512-53.44c1.408-3.712 1.408-7.808-.032-11.488l-20.48-53.472 44.512-36.032c3.072-2.496 5.12-6.016 5.728-9.92l8.992-56.544 56.544-8.992c3.904-.608 7.424-2.656 9.92-5.728l36-44.48 53.44 20.512a16.063 16.063 0 0011.456 0l53.44-20.512 36.032 44.512c2.496 3.072 6.016 5.12 9.92 5.728l56.544 8.992 8.992 56.544c.608 3.904 2.656 7.424 5.728 9.92l44.512 36.032-20.512 53.44a16.063 16.063 0 000 11.456z" />
      <path d="M208.015 128.03c-26.464 0-48 21.536-48 48s21.536 48 48 48 48-21.536 48-48-21.536-48-48-48zm0 64c-8.832 0-16-7.168-16-16s7.168-16 16-16 16 7.168 16 16-7.168 16-16 16zM304.015 288.03c-26.464 0-48 21.536-48 48s21.536 48 48 48 48-21.536 48-48-21.536-48-48-48zm0 64c-8.832 0-16-7.168-16-16s7.168-16 16-16 16 7.168 16 16-7.168 16-16 16zM345.327 131.038c-7.168-5.12-17.184-3.456-22.304 3.712l-160 224c-5.152 7.168-3.488 17.184 3.68 22.304a15.943 15.943 0 009.312 2.976c4.992 0 9.92-2.336 13.024-6.688l160-224c5.152-7.2 3.456-17.184-3.712-22.304z" />
    </svg>
  )
}

export default SvgCampaign
