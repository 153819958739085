/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import Header from './header'
import { Footer } from './index'
import AppMenu from './app-menu'

const Layout = ({ children, className }) => {
  const MENU = [
    { id: 1, name: 'Anasayfa', url: '/', icon: 'Home' },
    { id: 2, name: 'Teklif Al', url: '/teklif-al', icon: 'Offer' },
    {
      id: 3,
      name: 'Hizmetlerimiz',
      url: '/hizmetlerimiz',
      icon: 'Services',
      hidden: 'mobile'
    },
    { id: 4, name: 'Kampanyalar', url: '/kampanyalar', icon: 'Campaign' },
    { id: 5, name: 'Blog', url: '/blog', icon: 'Blog' },
    {
      id: 6,
      name: 'İletişim',
      url: '/iletisim',
      icon: 'Contact',
      hidden: 'mobile'
    },
    {
      id: 7,
      name: 'İletişim',
      url:
        'http://api.whatsapp.com/send?phone=+905432113499&text=merhaba+teklif+almak+istiyorum',
      icon: 'Contact',
      hidden: 'desktop'
    }
  ]

  useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  return (
    <div className={['wrapper', className].join(' ')}>
      <Header pages={MENU} />
      <main>{children}</main>
      <Footer pages={MENU} />
      <AppMenu pages={MENU} />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
