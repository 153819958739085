import * as React from 'react'

function SvgInstagram(props) {
  return (
    <svg
      width={18}
      height={18}
      fill="none"
      viewBox="0 0 18 18"
      className=""
      {...props}>
      <path
        fill="#FCFCFC"
        d="M9.002 4.384a4.607 4.607 0 00-4.614 4.614 4.607 4.607 0 004.614 4.614 4.607 4.607 0 004.614-4.614 4.607 4.607 0 00-4.614-4.614zm0 7.614c-1.65 0-3-1.346-3-3s1.346-3 3-3 3 1.346 3 3-1.35 3-3 3zm5.879-7.803c0 .599-.482 1.076-1.076 1.076a1.076 1.076 0 111.076-1.076zm3.056 1.093c-.069-1.442-.398-2.719-1.454-3.771C15.431.465 14.154.136 12.713.063c-1.486-.084-5.94-.084-7.425 0-1.438.069-2.715.398-3.771 1.45S.136 3.842.063 5.283c-.084 1.486-.084 5.94 0 7.425.069 1.442.398 2.719 1.454 3.771s2.329 1.381 3.77 1.454c1.486.084 5.94.084 7.425 0 1.442-.069 2.719-.398 3.771-1.454 1.052-1.052 1.381-2.329 1.454-3.77.084-1.486.084-5.936 0-7.421zm-1.92 9.015a3.037 3.037 0 01-1.71 1.71c-1.185.47-3.996.362-5.305.362-1.31 0-4.124.104-5.305-.362a3.037 3.037 0 01-1.71-1.71c-.47-1.185-.362-3.996-.362-5.305 0-1.31-.104-4.124.362-5.305a3.037 3.037 0 011.71-1.71c1.185-.47 3.996-.362 5.305-.362 1.31 0 4.124-.104 5.305.362.787.313 1.393.92 1.71 1.71.47 1.185.362 3.996.362 5.305 0 1.31.108 4.124-.362 5.305z"
        opacity={0.9}
      />
    </svg>
  )
}

export default SvgInstagram
