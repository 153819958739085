import * as React from 'react'

function SvgFerdi(props) {
  return (
    <svg viewBox="0 0 480.151 480.151" className="" {...props}>
      <path d="M348.075 292.431a8 8 0 00-8 8v171.72a8 8 0 0016 0v-171.72a8 8 0 00-8-8zM305.219 114.506a7.999 7.999 0 00-10.743-.011l-29.68 29.656h-28.984a8 8 0 000 16h60.176a8 8 0 000-16h-8.568l18.344-18.344a8.002 8.002 0 00-.545-11.301z" />
      <path d="M393.987 259.351a130.113 130.113 0 00-48.712-60.168l-25.064-16.632c43.381-37.645 48.032-103.33 10.387-146.711s-103.33-48.032-146.711-10.387-48.032 103.33-10.387 146.711a103.996 103.996 0 0022.103 19.187l-10.888 7.72a350.69 350.69 0 00-104.064 116.84 37.016 37.016 0 00-4.576 17.752v5.872a36.639 36.639 0 0010.64 25.912 36.27 36.27 0 0025.664 10.704h35.696v96a8 8 0 0016 0v-96h72.104c17.483.057 31.702-14.07 31.759-31.553a31.559 31.559 0 00-.391-5.063l11.264-14.896a372.091 372.091 0 0056.632-106.4c.656-1.976 1.2-3.656 1.688-5.2a114.233 114.233 0 0142.064 52.312 122.189 122.189 0 018.88 46v160.8a8 8 0 0016 0v-160.8a137.867 137.867 0 00-10.088-52zm-281.608 100.8a20.153 20.153 0 01-14.256-5.96 20.701 20.701 0 01-6.048-14.656v-5.872a20.928 20.928 0 012.568-10.048 335.216 335.216 0 0155.408-74.168l7.416 62.704h-25.392a8 8 0 000 16h27.2l3.76 32h-50.656zm175.696-336.24l-53.512 22.52-31.104-15.552a87.478 87.478 0 0184.616-6.968zm-98.192 18.072l25.256 12.632-45.52 19.136a88.205 88.205 0 0120.264-31.768zm-25.008 51.16l140.256-59.056a88.507 88.507 0 0113.168 12.264l-151.424 79.32a83.022 83.022 0 01-2-32.528zm11.43 55.526a87.792 87.792 0 01-4.045-7.719l155.512-81.504c24.638 41.826 10.704 95.706-31.122 120.345-41.827 24.638-95.707 10.705-120.345-31.122zm2.914 211.482l-14.648-124.544c5.92-5.312 12.08-10.32 18.36-15.2l34.928 139.744h-38.64zm68.248-4.664a15.819 15.819 0 01-11.256 4.664h-1.856l-8-32h9.656a16.134 16.134 0 0111.608 5.04c5.989 6.249 5.921 16.128-.152 22.296zm72.88-142.336a356.41 356.41 0 01-54.264 101.84l-6.152 8.136c-.368-.408-.632-.88-1.016-1.28a32.076 32.076 0 00-22.904-9.696h-13.656l-25.48-102.072 14.496-10.272a103.374 103.374 0 0095.104-7.152l16.8 11.104c-.552 2-1.44 4.944-2.928 9.392z" />
    </svg>
  )
}

export default SvgFerdi
