import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import styles from './services.module.css'
import * as icons from './icons'

function Services({subpage,limit}) {
  return (
    <StaticQuery
      query={graphql`
        {
          services: allMarkdownRemark(
            filter: { fileAbsolutePath: { regex: "/data/hizmetlerimiz/" }, frontmatter: {type: {in: "services"}}},
            sort: { fields: frontmatter___sort }
          ) {
            edges {
              node {
                id
                fields{
                slug
                }
                frontmatter {
                  url
                  content
                  summary
                  title
                  icon
                  color
                  parent
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        return (
          <section
            role="main"
            aria-label="Hizmetlerimiz"
            className={[styles.services, styles.pb200].join(' ')}
            itemtype="http://schema.org/Product"
            itemscope>
            <div className="container position-relative">
              <div className={[styles.head,subpage?'d-none':''].join(' ')}>
                <span className="h6">En iyi Hizmet Garantisi</span>
                <h2>Hizmetlerimiz</h2>
                <div className="col-sm-8 mx-auto">
                  <p>
                      Hizmetlerimiz Timurlar Çatalca sigorta olarak aşağıdaki sigorta branşları dahil olmak üzere 7/24 hizmet kapsamında tüm sigorta ürünlerimiz ile hizmetinizdeyiz.

                  </p>
                </div>
              </div>
              <div className="row">
                {data.services.edges.map(({ node},i) => {
                   if(i>limit-1){
                    return false;
                   }
                  const icon = node.frontmatter.icon
                  const color = node.frontmatter.color || '#fff'
                  return (
                    <div key={node.id} className="col-md-4">
                      <div className={styles.servicesItem}>
                        <span className={[styles.border,'mx-auto mx-md-0'].join(' ')}>
                          {' '}
                          {icon &&
                            React.createElement(icons[icon], {
                              color: `${color}`
                            })}
                        </span>
                        <h3 className="h4">
                          <Link to={node.fields.slug}>
                            {node.frontmatter.title}
                          </Link>
                        </h3>
                        <p className="small">{node.frontmatter.summary}</p>
                      </div>
                      <Schema product={node.frontmatter} />
                    </div>
                  )
                })}
                <Link to="/hizmetlerimiz" className={`btn btn-primary mx-auto ${subpage?'d-none':''}`}>
                  Tüm Hizmetler
                </Link>
              </div>
            </div>
          </section>
        )
      }}
    />
  )
}
export default Services

function Schema({ product }) {
  return (
    <div itemType="http://schema.org/Product" itemScope>
      <meta itemProp="name" content={product.title} />
        <link itemProp="image" href="https://example.com/photos/16x9/photo.jpg"/>
      <meta itemProp="description" content={product.content} />
      <div itemProp="offers" itemType="http://schema.org/Offer" itemScope>
        <link itemProp="url" href={`http://www.timurlarcatalca.com${product.parent}`} />
        <meta itemProp="availability" content="https://schema.org/InStock" />
        <meta itemProp="priceCurrency" content="TL" />
        <meta
          itemProp="itemCondition"
          content="https://schema.org/UsedCondition"
        />
        <meta itemProp="price" content="119.99" />
        <meta itemProp="priceValidUntil" content="2020-11-20" />
        <div
          itemProp="seller"
          itemType="http://schema.org/Organization"
          itemScope>
          <meta itemProp="name" content="Timurlar Çatalca Sigorta" />
        </div>
      </div>
      <div
        itemProp="aggregateRating"
        itemType="http://schema.org/AggregateRating"
        itemScope>
        <meta itemProp="reviewCount" content="51" />
        <meta itemProp="ratingValue" content="5" />
      </div>
        <meta itemProp="sku" content="0446310786"/>
        <div itemProp="brand" itemType="http://schema.org/Brand" itemScope>
            <meta itemProp="name" content="Timurlar Çatalca Sigorta"/>
        </div>
    </div>
  )
}
