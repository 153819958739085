import React from 'react'
import Slider from 'react-slick'
import Img from 'gatsby-image'
import styles from './section.module.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Html from '../html'
export default function SimpleSlider({ data }) {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  }
  return (
    <Slider {...settings}>
      {data.map(({ node }) => {
        const item = { ...node.frontmatter }
        return (
          <div key={item.title} className="item">
            <div className="row">
              <div className="col-lg-5">
                <div className={styles.textArea}>
                  <span className="h6">{item.headTitle}</span>
                  <h2>{item.title}</h2>
                  <p className="hero">{item.hero}</p>
                  <div className="content">
                    <Html>{item.content}</Html>
                  </div>
                  {item.buttonText && (
                    <div className="d-flex justify-content-center justify-content-md-start">
                      <a href="{item.url}" className="btn btn-primary">
                        {item.buttonText}
                      </a>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-lg-5 offset-lg-1 mt-4">
                <Img fluid={item.featuredImage.childImageSharp.fluid} />
              </div>
            </div>
          </div>
        )
      })}
    </Slider>
  )
}
