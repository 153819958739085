import React from 'react'
import styles from './footer.module.css'
import { Facebook, Instagram, Linkedin, Mail, Phone, Twitter } from './icons'
import Logo from './logo'
import { Link } from 'gatsby'
function Footer({ pages }) {
  return (
    <footer className={styles.footer}>
      <div className="container">
        <div className="row">
          <div className="col-md-3 mb-4 mb-md-0">
            <div className="mb-4">
              <Logo white />
            </div>
            <p className={styles.lastP}>34 Şirketten daima en uygun fiyat garantisi</p>
          </div>
          <div className="col-md-3 mb-4 mb-md-0">
            <span className="h5 font-weight-bold">Sayfalar</span>
            <ul>
              {pages.map((page) => {
                if (page.hidden === 'desktop') {
                  return null
                }
                return (
                  <li key={page.id}>
                    <Link to={page.url}>{page.name}</Link>
                  </li>
                )
              })}
            </ul>
          </div>
          <div className="col-md-3 mb-4 mb-md-0">
            <span className="h5 font-weight-bold">İletişim</span>
            <ul className="contact">
              <li>
                <a className={styles.mail} href="mailto:info@timurlarcatalca.com">
                  <Mail /> info@timurlarcatalca.com
                </a>
              </li>
              <li>
                <a className={styles.phone} href="tel:(0543) 211 34 99">
                  <Phone /> (0543) 211 34 99
                </a>
              </li>
              <li>
                <a className={styles.phone} href="tel:(0212) 789 4186">
                  <Phone /> (0212) 789 4186
                </a>
              </li>
            </ul>
            <p className={styles.address}>
              <a href="/">
                {' '}
                Timurlar Sigorta Çatalca Ferhatpaşa Mah. Tahtaköprü Caddesi No:35/A
                Çatalca/İstanbul
              </a>
            </p>
          </div>
          <div className="col-md-3">
            <span className="h5 font-weight-bold" id="socialTitle">Sosyal Medya Hesapları</span>
              <ul
                className={[
                  'd-flex justify-content-between',
                  styles.social
                ].join(' ')}
                aria-labelledby="socialTitle">
                <li>
                  <a
                    aria-label="timurlar facebook sayfası"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.facebook.com/timurlarcatalca/">
                    <Facebook />
                  </a>
                </li>
                <li>
                  <a
                    aria-label="timurlar twitter sayfası"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.twitter.com/timurlarcatalca">
                    <Twitter />
                  </a>
                </li>
                <li>
                  <a
                    aria-label="timurlar instagram sayfası"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.instagram.com/timurlarcatalca/">
                    <Instagram />
                  </a>
                </li>
                <li>
                  <a
                    aria-label="timurlar linkedin sayfası"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.linkedin.com/company/timurlarcatalca">
                    <Linkedin />
                  </a>
                </li>
                <li className="d-none d-md-block">&nbsp;</li>
              </ul>
          </div>
        </div>
        <span className={styles.copyright}>
          Copyright © {new Date().getFullYear()}
          {` `}
          <a href="https://www.timurlarcatalca.com">Çatalca Sigorta</a>
        </span>
      </div>
    </footer>
  )
}
export default Footer
